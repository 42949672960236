import { useNetworksInOrganization } from "backend/resources/network/network";
import { NetworkRoleType } from "backend/resources/userRole/types";
import { CarespacePageTabs } from "components/CarespacePage/Tabs/carespaceTypes";
import { useAppNavigate } from "lib/routing";
import { navigateToCarespaceTab } from "routes/routesUtil";

export default function CarespacesTab() {
  const navigate = useAppNavigate();

  const { networks } = useNetworksInOrganization();

  return (
    <div className="gap-4 grid grid-cols-[2fr,1.5fr,1fr] items-center text-xs md:text-base">
      <p className="font-medium">Name</p>
      <p className="font-medium text-center">Patient(s)</p>
      <p className="font-medium text-center">CN(s)</p>
      {networks?.map((network) => (
        <>
          <button
            className="text-start"
            onClick={() => navigateToCarespaceTab(CarespacePageTabs.MAIN)}>
            {network.name}
          </button>
          <button
            className="line-clamp-1"
            onClick={() => navigateToCarespaceTab(CarespacePageTabs.MAIN)}>
            {network.user_adlo.map((adlo) => adlo.first_name).join(", ")}
          </button>
          <button
            onClick={() => navigateToCarespaceTab(CarespacePageTabs.MAIN)}>
            <p className="line-clamp-1">
              {network.user_role
                .filter((user_role) => user_role.role === NetworkRoleType.CARE_NAVIGATOR)
                .map((user_role) => user_role?.user?.first_name)
                .join(", ")}
            </p>
          </button>
        </>
      ))}
    </div>
  );
}
