import { DatePicker } from "@mui/x-date-pickers";
import { QUERY_KEYS } from "backend/queryKeys";
import { useQueryUserAssessmentsBySlug } from "backend/resources/userAssessment";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { beneficiaryDisclaimerText } from "components/CarespacePage/PAFSubmission/constants";
import { DementiaStageAssessmentTool, PhoneType, ResidenceType } from "components/CarespacePage/PAFSubmission/pafEnums";
import { getCurrentValueForSelect, getFirstAssessmentId, getOptionsForUserAssessments, getOptionsFromEnum, PAFSchema } from "components/CarespacePage/PAFSubmission/pafFormUtil";
import { DefaultCheckbox } from "components/Checkbox";
import { RadioButtonsGroup } from "components/RadioGroup";
import { Select } from "components/Select/Select";
import dayjs from "dayjs";
import { FC, useEffect } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { CustomFormField } from "shared/ui/form";
import { Input } from "shared/ui/input";
import { useActiveNetworkId } from "state/network/network";
import { z } from "zod";


interface PatientInformationInputsProps<T extends FieldValues>  {
  form: UseFormReturn<T>;
  isReadOnly: boolean;
  navigateToAssessments: () => void;
}

export const PatientInformationInputs: FC<PatientInformationInputsProps<z.infer<typeof PAFSchema>>>  = ({ form, isReadOnly, navigateToAssessments }) => {
  const networkId = useActiveNetworkId()
  const formValues = form.watch()
  const showFastTool = formValues.staging_tool == DementiaStageAssessmentTool.FAST;
  const hasPromisId = formValues.promis_id !== '';
  const promisAssessments = useQueryUserAssessmentsBySlug(networkId, "PROMIS-GH", QUERY_KEYS.pafPROMISAssessments)?.data || [];
  const fastAssessments = useQueryUserAssessmentsBySlug(networkId, "Health", QUERY_KEYS.pafFASTAssessments)?.data || [];
  const cdrAssessments = useQueryUserAssessmentsBySlug(networkId, "CDR", QUERY_KEYS.pafCDRAssessments)?.data || [];

  useEffect(() => {
    form.setValue("cdr_id", getFirstAssessmentId(cdrAssessments));
    form.setValue("fast_id", getFirstAssessmentId(fastAssessments));
    form.setValue("staging_id", getFirstAssessmentId(showFastTool ? fastAssessments : cdrAssessments))
  }, [showFastTool, cdrAssessments, fastAssessments])
  
  useEffect(() => {
    form.setValue("promis_id", getFirstAssessmentId(promisAssessments));
  }, [hasPromisId, promisAssessments]);

  return (
    <>
      {/* First Name */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        inlineLabel={true}
        labelWidth="200px"
        labelName="First Name"
        fieldName="first_name"
        children={(field) => (
          <Input {...field} className="max-w-[400px]" />
        )}
      />
      {/* Middle Initial */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        inlineLabel={true}
        labelWidth="200px"
        labelName="Middle Initial"
        fieldName="middle_initial"
        children={(field) => (
          <Input {...field} className="max-w-[400px]" />
        )}
      />
      {/* Last Name */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        inlineLabel={true}
        labelWidth="200px"
        labelName="Last Name"
        fieldName="last_name"
        children={(field) => (
          <Input {...field} className="max-w-[400px]" />
        )}
      />
      {/* Email */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        inlineLabel={true}
        labelWidth="200px"
        labelName="Email"
        fieldName="email"
        children={(field) => (
          <Input {...field} className="max-w-[400px]" />
        )}
      />
      {/* Address */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        inlineLabel={true}
        labelWidth="100px"
        labelName="Address"
        fieldName="address_line"
        children={(field) => (
          <Input {...field} className="max-w-[500px]" />
        )}
      />
      {/* City */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        inlineLabel={true}
        labelWidth="100px"
        labelName="City"
        fieldName="address_city"
        children={(field) => (
          <Input {...field} className="max-w-[500px]" />
        )}
      />
      <div className="grid grid-cols-2 gap-4 max-w-[615px]">
        {/* State */}
        <CustomFormField 
          form={form}
          disabled={isReadOnly}
          inlineLabel={true}
          labelWidth="100px"
          labelName="State"
          fieldName="address_state"
          children={(field) => (
            <Input {...field} className="w-[175px]" />
          )}
        />
        {/* Zip Code */}
        <CustomFormField 
          form={form}
          disabled={isReadOnly}
          inlineLabel={true}
          labelWidth="100px"
          labelName="Zip Code"
          fieldName="address_postalCode"
          children={(field) => (
            <Input {...field} className="w-[175px]" />
          )}
        />
      </div>
      <div className="grid gap-4 grid-cols-2 max-w-[615px]">
        {/* Phone */}
        <CustomFormField 
          form={form}
          disabled={isReadOnly}
          inlineLabel={true}
          labelWidth="100px"
          labelName="Phone"
          fieldName="phone"
          children={(field) => (
            <Input {...field} className="w-[175px]" />
          )}
        />
        {/* Phone Type */}
        <CustomFormField 
          form={form}
          disabled={isReadOnly}
          inlineLabel={true}
          labelWidth="100px"
          labelName="Type"
          fieldName="phone_type"
          children={(field) => (
            <Select 
              classNames="max-w-[185px] w-[185px]"
              options={getOptionsFromEnum(PhoneType)}
              currentOption={getCurrentValueForSelect(field)}
              {...field}
            />
          )}
        />
      </div>
      {/* Date of Birth */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        labelName="Date of Birth"
        fieldName="date_of_birth"
        children={(field) => (
          <DatePicker 
            {...field} 
            value={field.value ? dayjs(field.value) : null}
            onChange={(date) => field.onChange(date ? date : null)}
          />
        )}
      />
      {/* Residence Type */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        labelWidth="150px"
        labelName="Residence Type"
        fieldName="residence_type"
        children={(field) => (
          <RadioButtonsGroup 
            options={getOptionsFromEnum(ResidenceType)}
            row={false}
            {...field}
          />
        )}
      />
      {/* Not Nursing Home */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        inlineLabel={true}
        labelName="Check to confirm patient is not a long-term nursing home resident"
        fieldName="not_nursing_home"
        children={(field) => (
          <DefaultCheckbox {...field} />
        )}
      />
      <p className="mt-4 mb-4">{beneficiaryDisclaimerText}</p>
      {/* Medicare Beneficiary Identifier */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        inlineLabel={true}
        labelWidth="400px"
        labelName="Patient Medicare Beneficiary Identifier"
        fieldName="mbi"
        children={(field) => (
          <Input {...field} className="max-w-[200px]" />
        )}
      />
      {/* Medicaid Id */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        inlineLabel={true}
        labelWidth="400px"
        labelName="Patient Medicaid ID number (if applicable)"
        fieldName="medicaid_id"
        children={(field) => (
          <Input {...field} className="max-w-[200px]" />
        )}
      />
      {/* Staging Tool */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        labelName="Patient dementia stage: (CDR, FAST):"
        fieldName="staging_tool"
        inlineLabel={true}
        labelWidth="400px"
        children={(field) => (
          <Select 
            classNames="max-w-[200px] w-[200px]"
            options={getOptionsFromEnum(DementiaStageAssessmentTool)}
            currentOption={getCurrentValueForSelect(field)}
            {...field}
          />
        )}
      />
      {/* Dementia Stage Score
        * needs to be a ternary operator otherwise button icons don't render. 
        * cause: unknown */}
      {showFastTool?
        <div className={"flex space-x-4"}>
          <CustomFormField 
            form={form}
            disabled={true}
            inlineLabel={true}
            labelWidth="400px"
            labelName={"FAST Score"}
            fieldName="fast_id"
            children={(field) => 
              <Select 
                classNames="max-w-[200px] w-[200px]"
                options={getOptionsForUserAssessments(fastAssessments)}
                currentOption={getCurrentValueForSelect(field)}
                {...field}
              />
            }
          />
          <ButtonWithIcon 
            onClick={navigateToAssessments}
            text={`Take FAST Assessment`}
            icon={IconOption.PLUS}
          />
        </div>
      : 
        <div className={"flex space-x-4"}>
          <CustomFormField 
            form={form}
            disabled={true}
            inlineLabel={true}
            labelWidth="400px"
            labelName={"CDR Score"}
            fieldName="cdr_id"
            children={(field) => 
              <Select 
                classNames="max-w-[200px] w-[200px]"
                options={getOptionsForUserAssessments(cdrAssessments)}
                currentOption={getCurrentValueForSelect(field)}
                {...field}
              />
            }
          />
          <ButtonWithIcon 
            onClick={navigateToAssessments}
            text={`Take CDR Assessment`}
            icon={IconOption.PLUS}
          />
        </div>
      }
      {/* Dementia Stage Score */}
      <div className="flex space-x-4">
        <CustomFormField 
          form={form}
          disabled={true}
          inlineLabel={true}
          labelWidth="400px"
          labelName={`Promis 10 Score`}
          fieldName="promis_id"
          children={(field) => 
            <Select 
              classNames="max-w-[200px] w-[200px]"
              options={getOptionsForUserAssessments(promisAssessments)}
              currentOption={getCurrentValueForSelect(field)}
              {...field}
            />
          }
        />
        <ButtonWithIcon 
          onClick={navigateToAssessments}
          text={"Take Promis 10 Assessment"}
          icon={IconOption.PLUS}
        />
      </div>
    </>
  )
}