import { DatePicker } from "@mui/x-date-pickers";
import { downloadPafFile } from "backend/functions/generatePafFile/generatePafFile";
import { useNetwork } from "backend/resources/network/network";
import { usePAFSubmissionQuery, useUpsertPAFSubmission } from "backend/resources/pafSubmission/pafSubmission";
import { Button } from "components/Button";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { ClinicianInputs } from "components/CarespacePage/PAFSubmission/ClinicianInputs";
import { hasPCGLabel } from "components/CarespacePage/PAFSubmission/constants";
import { InitialAssessmentInputs } from "components/CarespacePage/PAFSubmission/InitialAssessmentInputs";
import { AssessmentType, HasPrimaryCareGiver, PAFStatus, YesNo } from "components/CarespacePage/PAFSubmission/pafEnums";
import { formatPAFSubmissionData, generateDefaultFormData, getCurrentValueForSelect, getOptionsFromEnum, PAFSchema } from "components/CarespacePage/PAFSubmission/pafFormUtil";
import { usePAFSubmissionForm } from "components/CarespacePage/PAFSubmission/pafHooks";
import { PAFInformationSection } from "components/CarespacePage/PAFSubmission/PAFInformationSection";
import { PatientInformationInputs } from "components/CarespacePage/PAFSubmission/PatientInformationInputs";
import { PrimaryCareGiverInputs } from "components/CarespacePage/PAFSubmission/PrimaryCareGiverInputs";
import { PrimaryCarePhysicianInputs } from "components/CarespacePage/PAFSubmission/PrimaryCarePhysicianInputs";
import { ReassessmentInputs } from "components/CarespacePage/PAFSubmission/ReassessmentInputs";
import { buildPAFFileName, getGuideAlignmentPillLabel } from "components/CarespacePage/Tabs/CarespaceMainTab/carespaceMainTabUtil";
import { GuideAlignmentPillColorMap, GuideAlignmentStatus } from "components/CarespacePage/Tabs/CarespaceMainTab/constants";
import { CarespacePageTabs } from "components/CarespacePage/Tabs/carespaceTypes";
import { Pill } from "components/Pill";
import { Select } from "components/Select/Select";
import dayjs from "dayjs";
import { useAppNavigate, useNavigateBack } from "lib/routing";
import React, { useEffect, useState } from "react";
import { FieldValues } from "react-hook-form";
import { navigateToCarespaceTab } from "routes/routesUtil";
import { CustomFormField, Form, FormRoot } from "shared/ui/form";
import { useActiveNetworkId } from "state/network/network";
import { z } from "zod";


interface PAFSubmissionFormProps<T extends FieldValues> {
  isReadOnly: boolean;
}

export const PAFSubmissionForm: React.FC<PAFSubmissionFormProps<z.infer<typeof PAFSchema>>> = ({ isReadOnly }) => {
  const navigateBack = useNavigateBack()
  const networkId = useActiveNetworkId();
  const { data, isLoading } = useNetwork(networkId);
  const userAdlo = data?.user_adlo[0];
  const queryResponse = networkId ? usePAFSubmissionQuery(networkId) : { data: undefined };
  const pafSubmission = queryResponse.data?.[0]; // get most recent pafSubmission
  const form = usePAFSubmissionForm(generateDefaultFormData(pafSubmission));

  const pafStatus = pafSubmission?.status as PAFStatus;
  const guideAlignmentStatus = data?.guide_status as GuideAlignmentStatus;
  const showDownloadButton = pafStatus == PAFStatus.READY || guideAlignmentStatus == GuideAlignmentStatus.SUBMITTED || guideAlignmentStatus == GuideAlignmentStatus.ALIGNED;
  const guideAlignmentPillLabel = getGuideAlignmentPillLabel(pafStatus, guideAlignmentStatus);
  
  const navigate = useAppNavigate();
  const { mutateAsync: upsertPAFSubmission } = useUpsertPAFSubmission();
  const [hasErrors, setHasErrors] = useState<boolean>(false)

  // show input states
  const formValues = form.watch();
  const showPCGInputs = formValues.has_pcg == HasPrimaryCareGiver.YES_ONE || formValues.has_pcg == HasPrimaryCareGiver.YES_MULTIPLE
  const showPCPInputs = formValues.patient_pcp == YesNo.YES;
  const showInitialAssessmentInputs = formValues.assessment_type == AssessmentType.INITIAL_ASSESSMENT;
  const showReassessmentInputs = formValues.assessment_type == AssessmentType.REASSESSMENT;
  
  useEffect(() => {
    const defaultData = generateDefaultFormData(pafSubmission)
    form.reset({
      ...defaultData,
      // these values need to be here since they are dependent on queries later in render
      // otherwise would get overwritten in some cases where the form resets
      promis_id: formValues.promis_id ? formValues.promis_id : defaultData.promis_id,
      zbi_id: formValues.zbi_id ? formValues.zbi_id : defaultData.zbi_id
    });
  }, [pafSubmission, form]);
  
  const navigateToAssessments = () => {
    onSubmit(formValues, PAFStatus.DRAFT, false);
    navigateToCarespaceTab(CarespacePageTabs.ASSESSMENTS)
  };

  const saveAsDraft = () => {
    onSubmit(formValues, PAFStatus.DRAFT, true);
  }

  const onSubmit = async (data: z.infer<typeof PAFSchema>, formStatus: PAFStatus, shouldGoBack: boolean) => {
    const pafFormData = formatPAFSubmissionData(data, networkId, formStatus);

    await upsertPAFSubmission(pafFormData)
    setHasErrors(false)
    
    // Navigate back after submission
    if(shouldGoBack) {
      navigateBack()
    }
  };

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <div>
      <div className="flex flex-row items-center mb-4">
        <span className="mr-4">Status</span>
        <Pill 
          className="mr-4"
          label={guideAlignmentPillLabel}
          background={GuideAlignmentPillColorMap[guideAlignmentPillLabel]}
        />
        {showDownloadButton && (
          <ButtonWithIcon 
            onClick={() => {
              const fileName = buildPAFFileName(userAdlo ?? null)
              downloadPafFile(networkId, fileName)
            }}
            text="Download"
            icon={IconOption.DOWNLOAD}
          />
        )}
      </div>
      <Form {...form}>
        <FormRoot id={"paf-submission-form"}>
          {/* Assessments Information */}
          <PAFInformationSection 
            buttonLabel={"Assessment Information"}
          >
            {/* Assessment Type */}
            <CustomFormField
              form={form}
              inlineLabel={true}
              labelWidth={"200px"}
              disabled={isReadOnly}
              labelName="Assessment Type"
              fieldName="assessment_type"
              children={(field) => (
                <Select
                  classNames="mb-10"
                  options={getOptionsFromEnum(AssessmentType)}
                  currentOption={getCurrentValueForSelect(field)}
                  value={field.value}
                  {...field}
                />
              )}
            />
            {showInitialAssessmentInputs && (
              <InitialAssessmentInputs 
                form={form}
                isReadOnly={isReadOnly}
              />
            )}
            {showReassessmentInputs && (
              <ReassessmentInputs
                form={form}
                isReadOnly={isReadOnly}
              />
            )}
            {/* Assessment Date */}
            <CustomFormField
              form={form}
              disabled={isReadOnly}
              labelName="Date of Current Assessment"
              fieldName="assessment_date"
              children={(field) => (
                <DatePicker 
                  {...field} 
                  value={field.value ? dayjs(field.value) : null}
                  onChange={(date) => field.onChange(date ? date : null)}
                />
              )}
            />
          </PAFInformationSection>
          {/* Patient Information Information */}
          <PAFInformationSection 
            buttonLabel={"Patient Information"}
          >
            <PatientInformationInputs form={form} isReadOnly={isReadOnly} navigateToAssessments={navigateToAssessments}/>
          </PAFInformationSection>
          {/* PCP Information */}
          <PAFInformationSection 
            buttonLabel={"PCP Information"}
          >
            {/* Patient has PCP */}
            <CustomFormField 
              form={form}
              disabled={isReadOnly}
              inlineLabel={true}
              labelWidth="400px"
              labelName="Does the patient have a primary care provider?"
              fieldName="patient_pcp"
              children={(field) => (
                <Select 
                  classNames="max-w-[200px] w-[200px]"
                  options={getOptionsFromEnum(YesNo)}
                  currentOption={getCurrentValueForSelect(field)}
                  {...field}
                />
              )}
            />
            <div style={{ display: showPCPInputs ? 'block' : 'none' }}>
              <PrimaryCarePhysicianInputs form={form} isReadOnly={isReadOnly} />
            </div>
          </PAFInformationSection>
          {/* PCG Information */}
          <PAFInformationSection 
            buttonLabel={"PCG Information"}
          >
            {/* Has Patient Caregiver */}
            {/* Made custom label to accommodate for length of hasPCGLabel */}
            <div className="flex mb-6">            
              <div className="max-w-[600px] mr-4">{hasPCGLabel}</div>
              <CustomFormField 
                form={form}
                disabled={isReadOnly}
                labelName=""
                fieldName="has_pcg"
                inlineLabel={true}
                children={(field) => (
                  <Select 
                    classNames="max-w-[200px] w-[200px]"
                    options={getOptionsFromEnum(HasPrimaryCareGiver)}
                    currentOption={getCurrentValueForSelect(field)}
                    {...field}
                  />
                )}
              />
            </div>
            <div style={{ display: showPCGInputs ? 'block' : 'none' }}>
              <PrimaryCareGiverInputs form={form} isReadOnly={isReadOnly} navigateToAssessments={navigateToAssessments} />
            </div>
          </PAFInformationSection>
          {/* Clinician Information */}
          <PAFInformationSection 
            buttonLabel={"Clinician Information"}
          >
            <ClinicianInputs form={form} isReadOnly={isReadOnly} />
          </PAFInformationSection>
          {!isReadOnly && (
            <div className="flex gap-4 justify-end w-full">
              <Button 
                type="button"
                intent="primary"
                onClick={(e) => {
                  navigateBack()
                }}
              >
                Cancel
              </Button>
              <Button
                type="button"
                intent="primary"
                onClick={(e) => {
                  e.preventDefault();
                  saveAsDraft()
                }}
              >
                Save as Draft
              </Button>
              <Button
                type="button"
                intent="primary"
                onClick={form.handleSubmit(
                  (data: z.infer<typeof PAFSchema>) => onSubmit(data, PAFStatus.READY, true),
                  (errors) => {
                    console.log(errors)
                    setHasErrors(true)
                  }
                )}
              >
                Save as Ready
              </Button>
            </div>
          )}
        </FormRoot>
        {hasErrors && <p>Found Errors. Please review form for any required fields.</p>}
      </Form>
    </div>
  );
}
