import { ReactComponent as ActivitiesIconSvg } from "assets/activities-icon.svg";
import { ReactComponent as ArchiveIcon } from "assets/archive.svg";
import { ReactComponent as CancelIconSvg } from "assets/cancel-icon.svg";
import { ReactComponent as ChatIconSvg } from "assets/chat-icon.svg";
import { ReactComponent as CheckmarkIconSvg } from "assets/checkmark-icon.svg";
import { ReactComponent as CirclePlusIcon } from "assets/circle-plus.svg";
import { ReactComponent as TrashIcon } from "assets/delete-icon.svg";
import { ReactComponent as DownloadIconSvg } from "assets/download.svg";
import { ReactComponent as EditIconSvg } from "assets/edit-folder.svg";
import { ReactComponent as EmailIconSvg } from "assets/email-icon.svg";
import { ReactComponent as EventsIconSvg } from "assets/events-icon.svg";
import { ReactComponent as FinishLaterIcon } from "assets/finish later.svg";
import { ReactComponent as ForwardIconSvg } from "assets/forward-icon.svg";
import { ReactComponent as IntakeIconSvg } from "assets/intake-icon.svg";
import { ReactComponent as LogoutIconSvg } from "assets/logout.svg";
import { ReactComponent as SearchIcon } from "assets/magnifying-glass.svg";
import { ReactComponent as MessageIconSvg } from "assets/message.svg";
import { ReactComponent as MoreIcon } from "assets/more-actions.svg";
import { ReactComponent as NotesIconSvg } from "assets/notes-icon.svg";
import PADLOCK from "assets/padlock.png";
import { ReactComponent as PhoneIconSvg } from "assets/phone-icon.svg";
import PrivacyPolicy from "assets/privacy.png";
import { ReactComponent as RemoveIcon } from "assets/remove.svg";
import { ReactComponent as RestoreSvg } from "assets/restore.svg";
import { ReactComponent as RightArrowButtonIcon } from "assets/right-arrow-button.svg";
import { ReactComponent as SaveDraftIcon } from "assets/save_draft_button.svg";
import { ReactComponent as SendInvitationIcon } from "assets/send-invitation.svg";
import { ReactComponent as SwapIcon } from "assets/swap.svg";
import { ReactComponent as TasksIconSvg } from "assets/tasks-icon.svg";
import TermsOfService from "assets/tos.png";
import { ReactComponent as UploadIconSvg } from "assets/upload.svg";
import { ReactComponent as VideoIconSvg } from "assets/video_chat.svg";
import { ReactComponent as ViewAssessmentIcon } from "assets/view-assessment.svg";
import { ReactComponent as WarningIcon } from "assets/warning-blue.svg";
import { ReactComponent as WebIconSvg } from "assets/website-icon.svg";
import { Arrow } from "icons/Arrow";

export enum IconSize {
  extra_small = 16,
  small = 20,
  medium = 24,
  large = 32
}

export enum IconOption {
  PLUS,
  ARROW,
  CHAT_ICON,
  DOWN_ARROW,
  BACK_ARROW,
  UP_ARROW,
  EDIT,
  INTAKE,
  VIEW_ASSESSMENT,
  LATER,
  NOTES,
  TASK,
  ACTIVITY,
  EVENT,
  CANCEL,
  TRASH,
  CHECKMARK,
  SAVE_DRAFT,
  MORE,
  PHONE,
  EMAIL,
  WEB,
  PADLOCK,
  LOGOUT,
  SEARCH,
  SEND_INVITATION,
  TERMS_OF_SERVICE,
  PRIVACY_POLICY,
  ARCHIVE,
  MESSAGE,
  UPLOAD,
  SWAP,
  RESTORE,
  FORWARD,
  DOWNLOAD,
  VIDEO,
  REMOVE,
  WARNING
}

export const ButtonIconMap = {
  [IconOption.PLUS]: <CirclePlusIcon />,
  [IconOption.RESTORE]: <RestoreSvg />,
  [IconOption.UP_ARROW]: <Arrow className={`bg-brand-orange fill-white rounded-full p-1`} size={30} />,
  [IconOption.REMOVE]: <RemoveIcon className="rounded-full overflow-clip w-5 h-5" />,
  [IconOption.ARROW]: <RightArrowButtonIcon />,
  [IconOption.BACK_ARROW]: <RightArrowButtonIcon className="rotate-180" />,
  [IconOption.CHAT_ICON]: <ChatIconSvg />,
  [IconOption.DOWN_ARROW]: <RightArrowButtonIcon className="rotate-90" />,
  [IconOption.EDIT]: <EditIconSvg className="w-full" />,
  [IconOption.VIEW_ASSESSMENT]: <ViewAssessmentIcon />,
  [IconOption.INTAKE]: <IntakeIconSvg />,
  [IconOption.NOTES]: <NotesIconSvg />,
  [IconOption.LATER]: <FinishLaterIcon />,
  [IconOption.TASK]: <TasksIconSvg />,
  [IconOption.ACTIVITY]: <ActivitiesIconSvg />,
  [IconOption.EVENT]: <EventsIconSvg />,
  [IconOption.CANCEL]: <CancelIconSvg />,
  [IconOption.TRASH]: <TrashIcon />,
  [IconOption.CHECKMARK]: <CheckmarkIconSvg className="w-full" />,
  [IconOption.ARCHIVE]: <ArchiveIcon />,
  [IconOption.SEARCH]: <SearchIcon />,
  [IconOption.SEND_INVITATION]: <SendInvitationIcon />,
  [IconOption.SAVE_DRAFT]: <SaveDraftIcon />,
  [IconOption.MORE]: <MoreIcon />,
  [IconOption.PHONE]: <PhoneIconSvg />,
  [IconOption.MESSAGE]: <MessageIconSvg />,
  [IconOption.EMAIL]: <EmailIconSvg />,
  [IconOption.WEB]: <WebIconSvg />,
  [IconOption.UPLOAD]: <UploadIconSvg />,
  [IconOption.DOWNLOAD]: <DownloadIconSvg />,
  [IconOption.SWAP]: <SwapIcon />,
  [IconOption.VIDEO]: <VideoIconSvg />,
  [IconOption.PADLOCK]: <img src={PADLOCK} alt="" />,
  [IconOption.LOGOUT]: <LogoutIconSvg />,
  [IconOption.TERMS_OF_SERVICE]: <img src={TermsOfService} alt="" />,
  [IconOption.PRIVACY_POLICY]: <img src={PrivacyPolicy} alt="" />,
  [IconOption.FORWARD]: <ForwardIconSvg />,
  [IconOption.WARNING]: <WarningIcon />,
};