import type { RadioProps } from "@mui/material/Radio";
import Radio from "@mui/material/Radio";
import { ReactComponent as CheckedIcon } from 'assets/radio_check.svg';
import { ReactComponent as UncheckedIcon } from 'assets/radio_unchecked.svg';
import { cloneElement, ReactElement } from "react";

/**
 * Props for the DefaultRadioInput component.
 */
export interface DefaultRadioInputProps extends RadioProps {
  /**
   * The width to apply to the icon components.
   * @default 20
   */
  iconWidth?: number;
  /**
   * The height to apply to the icon components.
   * @default 20
   */
  iconHeight?: number;
}

/**
 * A custom Radio input component that uses custom SVG icons for the checked and unchecked states.
 * It adjusts the size of the icons based on the provided `iconWidth` and `iconHeight` props.
 *
 * @param {DefaultRadioInputProps} props - The props for the component.
 * @returns {JSX.Element} The rendered Radio component with custom icons.
 */
export function DefaultRadioInput({ iconWidth=20, iconHeight=20, ...props }: DefaultRadioInputProps) {

  /**
   * Clones the provided icon element and injects the width and height props.
   *
   * @param {ReactElement} element - The React element to clone.
   * @returns {ReactElement} The cloned React element with injected size props.
   */
  const renderIcon = (element: ReactElement | undefined) => {
    if (!element) return null;
    return cloneElement(element, {
      width: iconWidth,
      height: iconHeight,
    });
  };

  return (
    <Radio 
      checkedIcon={renderIcon(<CheckedIcon/>)}
      icon={renderIcon(<UncheckedIcon/>)}
      {...props}
    />
  );
}
