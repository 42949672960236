import BackButton from "components/BackButton/BackButton";
import { PAFSubmissionForm } from "components/CarespacePage/PAFSubmission/PAFSubmissionForm";
import { PageMainHeader } from "components/PageMainHeader";

interface PAFSubmissionPageProps {
  isReadOnly: boolean;
}

export const PAFSubmissionPage: React.FC<PAFSubmissionPageProps> = ({ isReadOnly }) => {
  return (
    <div className="flex flex-col gap-2 pb-16">
      {isReadOnly && <BackButton />}
      <PageMainHeader text="Guide Alignment" className="mb-4" />
      <PAFSubmissionForm isReadOnly={isReadOnly}/>
    </div>
  );
}
