import { downloadPafFile } from "backend/functions/generatePafFile/generatePafFile";
import { NetworksWithAdlosAndCaregivers, useUpdateNetworkGuideStatus } from "backend/resources/network/network";
import { AdloInitials } from "components/AdloComponent/AdloInitials";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { PAFStatus } from "components/CarespacePage/PAFSubmission/pafEnums";
import { CarespaceGuideStatusResponsiveModal } from "components/CarespacePage/Tabs/CarespaceMainTab/CarespaceGuideStatusResponsiveModal";
import { buildPAFFileName, getGuideAlignmentPillLabel, getPAFButtonLabel } from "components/CarespacePage/Tabs/CarespaceMainTab/carespaceMainTabUtil";
import { GuideAlignmentPillColorMap, GuideAlignmentStatus, PAFButtonLabel } from "components/CarespacePage/Tabs/CarespaceMainTab/constants";
import { DefaultCheckbox } from "components/Checkbox";
import { Pill } from "components/Pill";
import { CareCentralRoute, useAppNavigate } from "lib/routing";
import { useState } from "react";
import { formatDateNoCurrentYear } from "utils/timeUtil";

interface CarespaceInfoBannerProps {
  network?: NetworksWithAdlosAndCaregivers | null;
}

export const CarespaceInfoBanner = ({
  network,
}: CarespaceInfoBannerProps) => {
  const navigate = useAppNavigate();
  const userAdlo = network?.user_adlo[0]
  const networkId = network?.id;
  const pafSubmission = network?.paf_submission && network?.paf_submission.length > 0 ? network?.paf_submission[0] : undefined
  const networkGuideStatus = network?.guide_status as GuideAlignmentStatus;
  const networkGuideStatusUpdatedAt = network?.guide_status_updated_at;
  const pafSubmissionStatus = pafSubmission?.status as PAFStatus;
  const updateNetworkGuideStatus = useUpdateNetworkGuideStatus().mutate
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const showGuideDownloadButton = networkGuideStatus ==  GuideAlignmentStatus.ALIGNED || networkGuideStatus == GuideAlignmentStatus.SUBMITTED || pafSubmissionStatus == PAFStatus.READY;

  const handlePAFButtonClick = (getPAFButtonLabel: PAFButtonLabel) => {
    const path = (getPAFButtonLabel == PAFButtonLabel.EDIT || getPAFButtonLabel == PAFButtonLabel.NEW) 
      ? CareCentralRoute.PAF_SUBMISSION_EDIT 
      : CareCentralRoute.PAF_SUBMISSION_VIEW;
    navigate({
      path,
      params: { id: networkId ?? "" }
    })
  }

  const handleModalOnSuccess = () => {
    setIsModalOpen(false)
    const guideStatus: GuideAlignmentStatus = (
      pafSubmissionStatus == PAFStatus.READY && networkGuideStatus == GuideAlignmentStatus.SUBMITTED 
      ? GuideAlignmentStatus.ALIGNED : GuideAlignmentStatus.SUBMITTED
    )
    updateNetworkGuideStatus({ guideStatus, networkId })
  }

  const guideAlignmentLabel = getGuideAlignmentPillLabel(pafSubmissionStatus, networkGuideStatus)
  const pafButtonLabel = getPAFButtonLabel(guideAlignmentLabel)

  return (
    <div className={`text-md grid grid-cols-12 w-full mt-6 border border-gray-200 p-4`}>
      <div className="col-span-1 flex items-center justify-center h-full">
        <AdloInitials
          userAdlo={userAdlo}
          size={"default"}
        />
      </div>
      <div className="col-span-3 flex flex-col">
        <p className="text-lg">{userAdlo?.first_name} {userAdlo?.last_name}</p>
        {userAdlo?.birthday && <p>DOB: {new Date(userAdlo?.birthday).toLocaleDateString('en-US', { timeZone: 'UTC' })}</p>}
        {userAdlo?.sex && <p>Sex: {userAdlo?.sex}</p>}
        {userAdlo?.dyad && <p>Dyad: {userAdlo?.dyad}</p>}
        {/* TODO: Add MN Number */}
        {/* <p>MN: 12345</p> */}
      </div>
      <div className="col-span-4 flex flex-col">
        <div className="flex flex-row items-center mb-2">
          <span className="font-bold mr-4">Guide Alignment: </span>
          {showGuideDownloadButton && (
            <ButtonWithIcon 
              onClick={() => {
                const fileName = buildPAFFileName(userAdlo ?? null)
                downloadPafFile(networkId, fileName)
              }}
              text="Download"
              icon={IconOption.DOWNLOAD}
            />
          )}
        </div>
        <div className="flex flex-row items-center mb-2">
          <span className="mr-4">Status</span>
          <Pill 
            className="mr-4"
            label={guideAlignmentLabel}
            background={GuideAlignmentPillColorMap[guideAlignmentLabel]}
          />
          <ButtonWithIcon
            size="small"
            className="mr-4"
            onClick={() => 
              handlePAFButtonClick(pafButtonLabel)
            }
            icon={IconOption.ARROW}
            text={pafButtonLabel}
          />
          <div className="mr-2">
            {(pafSubmissionStatus == PAFStatus.READY && networkGuideStatus != GuideAlignmentStatus.ALIGNED) &&
              <DefaultCheckbox 
                onChange={() => setIsModalOpen(true)}
                label={networkGuideStatus == GuideAlignmentStatus.SUBMITTED ? "Mark as Aligned" : "Mark as Submitted"}
                checked={false}
              />
            }
            {(pafSubmissionStatus == PAFStatus.READY && networkGuideStatus == GuideAlignmentStatus.ALIGNED) &&
              <span>Aligned{
                networkGuideStatusUpdatedAt && (
                  `: ${formatDateNoCurrentYear(new Date(networkGuideStatusUpdatedAt))}`
                )}
              </span>
            }
          </div>
        </div>
      </div>
      {/* TODO: Add rest of Carespace Info*/}
      {networkGuideStatus != GuideAlignmentStatus.ALIGNED && (
        <CarespaceGuideStatusResponsiveModal 
          text={
            networkGuideStatus == GuideAlignmentStatus.SUBMITTED ? "Change status to aligned?" : "Change status to submitted?"
          }
          onClose={() => setIsModalOpen(false)}
          onSuccess={handleModalOnSuccess}
          isOpen={isModalOpen}
        />
      )}
    </div>
  )
}
