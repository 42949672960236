export const LocalStorageKeys = {
  activeAssessment: "active-assessment-store",
  appLayout: "app-layout",
  productOnboarding: "product-onboarding",
  assessment: "assessment",
  myPlan: "myPlan",
  invitation: "invitation",
  organization: "organization-storage",
  network: "network-storage",
  logoutReason: "logoutReason"
};

export function clearLocalStorageKeys() {
  for (const key in LocalStorageKeys) {
    localStorage.removeItem(
      LocalStorageKeys[key as keyof typeof LocalStorageKeys]
    );
  }
}
