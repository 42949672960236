import { PAFExpandableFormButton } from "components/CarespacePage/PAFSubmission/PAFExpandableFormButton";
import { PAFSchema } from "components/CarespacePage/PAFSubmission/pafFormUtil";
import { FC, ReactNode, useState } from "react";
import { FieldValues } from "react-hook-form";
import { z } from "zod";

interface PAFInformationSectionProps<T extends FieldValues> {
  children: ReactNode;
  buttonLabel: string;
}

export const PAFInformationSection: FC<PAFInformationSectionProps<z.infer<typeof PAFSchema>>> = ({ children, buttonLabel }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  
  return (
    <>
        {/* Assessments Information */}
        <PAFExpandableFormButton 
          onClick={() => setIsExpanded(!isExpanded)}
          rotate={isExpanded}
          label={buttonLabel}
        />
        <div style={{ display: isExpanded ? 'block' : 'none' }}>
          {children}
        </div>
    </>
  )
}