import { useGuideTasks } from "backend/resources/guide/guideTask";
import { useNetworksInOrganization } from "backend/resources/network/network";
import { useActiveOrgRole, useOrgs } from "backend/resources/orgRole";
import { Select } from "components/Select";
import { TaskTableRowType } from "components/Tables/TaskTable/TaskTableTypes";
import { FilterConfig, TaskFilter, TaskFilterTypes } from "components/TaskNavigatorPage/TaskFilters";
import { GuideCategories } from "components/TaskNavigatorPage/taskTableUtils";
import { TaskDueDateRange, useTaskFilterStore } from "state/taskFilter/taskFilter";

interface TaskFiltersProps {
  filterConfig: FilterConfig;
  shouldStackInputs?: boolean;
}

/**
 * TaskFilters Component
 * 
 * This component renders a set of filters for tasks based on the provided configuration.
 * It allows users to filter tasks by various criteria such as carespace, assignee, task type,
 * due date, category, and guide task.
 *
 * @param {FilterConfig} filterConfig - Configuration object to determine which filters to display
 */
export function TaskFilters({ filterConfig, shouldStackInputs = false }: TaskFiltersProps) {
  // Use the task filter store to manage filter state
  const { carespace: network, category, who, setCarespace: setNetwork, setCategory, setWho, dueDate, setDueDate, taskType, setTaskType, guideTask, setGuideTask } = useTaskFilterStore();

  // Fetch necessary data for populating filter options
  const { networks } = useNetworksInOrganization();
  const { data: guideTasks } = useGuideTasks();
  const { ownOrgIdentities } = useOrgs();
  const { data: activeOrgAndIdentity } = useActiveOrgRole();

  // Filter active organization roles for the 'Who' filter
  const activeOrgRoles = ownOrgIdentities?.filter(
    (organizationRole) =>
      organizationRole.organization_id === activeOrgAndIdentity?.organization_id
  );

  const whoOptions = [
    { value: "All", label: "All" },
    ...(activeOrgRoles?.map((orgRole) => ({
      label: `${orgRole.user.first_name} ${orgRole.user.last_name}`,
      value: orgRole.user.id,
    })) ?? [])
  ];

  return (
    <div className={`flex flex-col gap-y-4 w-full`}>
      {/* Render each filter based on the filterConfig */}
      <div className={`grid ${shouldStackInputs ? 'grid-cols-1' : 'grid-cols-2'} ${shouldStackInputs ? 'w-full' : 'w-[700px]'} gap-x-5 gap-y-4`}>
        {filterConfig[TaskFilterTypes.CARESPACE] && (
          <TaskFilter label={TaskFilterTypes.CARESPACE}>
            <Select
              currentOption={network ? { value: network, label: network } : { value: undefined, label: "All" }}
              options={[{ value: "All", label: "All" }, ...(networks?.map((network) => ({ label: network.name || 'Default', value: network.id })) ?? [])]}
              onChange={(networkId) => setNetwork(networkId)}
              classNames="w-full"
            />
          </TaskFilter>
        )}
        {filterConfig[TaskFilterTypes.WHO] && (
          <TaskFilter label={TaskFilterTypes.WHO}>
            <Select
              currentOption={who ? { label: who, value: who, } : { value: undefined, label: "All" }}
              options={whoOptions}
              onChange={(who) => setWho(who)}
              classNames="w-full"
            />
          </TaskFilter>
        )}
        {filterConfig[TaskFilterTypes.TASK_TYPE] && (
          <TaskFilter label={TaskFilterTypes.TASK_TYPE}>
            <Select
              currentOption={taskType ? { value: taskType, label: taskType } : { value: undefined, label: "All" }}
              options={[{ value: "All", label: "All" }, ...Object.values(TaskTableRowType).map((taskType) => ({ label: taskType, value: taskType }))]}
              onChange={(taskType) => setTaskType(taskType)}
              classNames="w-full"
            />
          </TaskFilter>
        )}
        {filterConfig[TaskFilterTypes.DUE_DATE] && (
          <TaskFilter label={TaskFilterTypes.DUE_DATE}>
            <Select
              currentOption={dueDate ? { value: dueDate, label: dueDate } : { value: TaskDueDateRange.All, label: TaskDueDateRange.All }}
              options={[...(Object.values(TaskDueDateRange)).map((date) => ({ label: date, value: date }))]}
              onChange={(date) => setDueDate(date)}
              classNames="w-full"
            />
          </TaskFilter>
        )}
        <div className={`grid grid-cols-1 ${shouldStackInputs ? 'w-full' : 'w-[700px]'} gap-y-4`}>
          {filterConfig[TaskFilterTypes.CATEGORY] && (
            <TaskFilter label={TaskFilterTypes.CATEGORY}>
              <Select
                currentOption={category ? { value: category, label: category } : { value: undefined, label: "All" }}
                options={[{ value: "All", label: "All" }, ...Object.values(GuideCategories).map((guideCategory) => ({ label: guideCategory, value: guideCategory }))]}
                onChange={(category) => setCategory(category)}
                classNames="w-full"
                disabled={!(taskType === TaskTableRowType.GUIDE || !taskType || taskType === "All")}
              />
            </TaskFilter>
          )}
          {filterConfig[TaskFilterTypes.GUIDE_TASK] && (
            <TaskFilter label={TaskFilterTypes.GUIDE_TASK}>
              <Select
                currentOption={guideTask ? { value: guideTask, label: guideTask } : { value: "All", label: "All" }}
                options={[{ value: "All", label: "All" }, ...(guideTasks?.map((guideTask) => ({ label: guideTask.title, value: guideTask.id })) ?? [])]}
                onChange={(guideTaskId) => setGuideTask(guideTaskId)}
                classNames="w-full"
                disabled={!(taskType === TaskTableRowType.GUIDE || !taskType || taskType === "All")}
              />
            </TaskFilter>
          )}
        </div>
      </div>
    </div>
  );
}
