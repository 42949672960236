import type { PAFSubmission, PAFSubmissionUpdate } from "backend/resources/pafSubmission/pafSubmission";
import { AssessmentType, CaregiverStatus, ClinicianAttestation, DementiaStageAssessmentTool, HasPrimaryCareGiver, InitialPatient, PhoneType, PTReferralSource, Race, ReassessmentReason, Relationship, ResidenceType, Sex, YesNo } from "components/CarespacePage/PAFSubmission/pafEnums";
import dayjs from "dayjs";
import { z } from "zod";

export const PAFSchema = z.object({
  id: z.number().optional(),

  // format should be YYYY-MM-DD
  assessment_date: z.string(),

  // Indication of whether this is an initial assessment or a re-assessment for the patient. Answer options include: Initial assessment or re-assessment.
  assessment_type: z.nativeEnum(AssessmentType),

  // If assessment_type is "initial assessment," then this item must indicate if this is for an existing patient or a new patient. 
  // (This item should be blank if this is a re-assessment.)
  initial_patient: z.nativeEnum(InitialPatient).optional(),

  // If assessment_type is "initial assessment," then this item must indicate the referral source.
  // Answer options include: Referred by a healthcare provider, Referred by a community based organization, or Self-referral.
  // (This item should be blank if this is a re-assessment.)
  pt_referral_source: z.nativeEnum(PTReferralSource).optional(),

  // If assessment_type is "re-assessment," then this item must indicate the reason for re-assessment of a currently aligned and assigned GUIDE participating patient.
  // Answer options include: Annual re-assessment, Re-assessment due to change in severity of patient's dementia, Re-assessment due to change in caregiver status.
  // (This item should be blank if this is an initial assessment.)
  reassessment_reason: z.nativeEnum(ReassessmentReason).optional(),

  // If reassessment_reason is "re-assessment due to change in caregiver status," then this item must indicate what the change was that prompted the reassessment.
  // Answer options include: New primary caregiver, Loss of  caregiver so patient is without a caregiver, Patient change in residence, or Other (please specify).
  // (This item should be blank if reassessment_reason is NOT set to "re-assessment due to change in caregiver status".)
  caregiver_status: z.nativeEnum(CaregiverStatus).optional(),

  // If caregiver_status is "Other (please specify)," this item must include text to indicate the other type of caregiver status change that has occurred to prompt the re-assessment.
  // (This item should be blank if caregiver_status is NOT set to "Other (please specify)".)
  caregiver_status_os: z.string().optional(),

  // Patient's first name
  first_name: z.string(),

  // patient's middle initial
  middle_initial: z.string().optional(),

  // patient's last name
  last_name: z.string(),

  // patient's home street address
  address_line: z.string(),

  // patient's city
  address_city: z.string(),

  // patient's state
  address_state: z.string(),

  // Patient's home Zip code in the USPS standard five digit format.
  // NOTE: Confirm the beneficiary resides within the Zip code-based service area.
  address_postalCode: z.string(),

  // patient's email
  email: z.string().optional(),

  // patient's phone number
  phone: z.string(),

  // phone type
  phone_type: z.nativeEnum(PhoneType),

  // Description of where the patient resides
  // Answer options include: private residence, assisted living facility, or memory care program (excludes nursing home level of care).
  residence_type: z.nativeEnum(ResidenceType),

  // Confirmation that the patient has NOT been a long-term nursing home resident for 4 months or longer and is not covered under the Medicare skilled nursing benefit.
  //Answer options include: Confirmed - Not nursing home resident or Nursing home resident.
  not_nursing_home: z.boolean(),

  // Patient date of birth. Date format must be numeric YYYY-MM-DD. 
  date_of_birth: z.string(),

  // Beneficiary’s current Medicare Beneficiary Identifier (MBI) number. MBIs must be 11 characters.
  // The 1st, 4th, 7th, 10th, and 11th characters will always be numbers. The 2nd, 5th, 8th, and 9th characters will always be upper-case letters,
  // except for S, L, O, I, B, and Z. The 3rd and 6th characters will be letters or numbers. 
  mbi: z.string(),

  // Medicaid Identification Number for patients who are also eligible for Medicaid in addition to Medicare.
  // Include this number if this is applicable to the patient. Otherwise, leave this item blank.
  medicaid_id: z.string().optional(),

  // The assessment tool used by the GUIDE clinician to measure the patient's dementia stage.
  // Answer options include: Functional Assessment Staging Tool (FAST), Clinical Dementia Rating (CDR).
  staging_tool: z.nativeEnum(DementiaStageAssessmentTool),

  // Score indicating dementia stage based on the assessment the GUIDE clinician used as indicated in staging_tool.
  //Answer should be formatted as a number with up to 2 decimal points based on the assessment used and scoring method for that assessment.
  staging_id: z.string().optional(),
  fast_id: z.string().optional(),
  cdr_id: z.string().optional(),

  // The PROMIS Global-10 is a 10-item patient-reported questionnaire used by the GUIDE clinician to gauge the patient's overall physical and mental health.
  // Answer should be formatted as whole numbers ranging from 8-40.  
  promis_id: z.string().optional(),

  // Indicator of whether the patient has a Primary Care Provider (PCP). 
  // Answer options include: Yes or No.
  // will be converted to boolean on form submission
  patient_pcp: z.nativeEnum(YesNo),

  // If "patient_pcp" is set to yes, then this item should indicate the PCP's first name.
  pcp_first_name: z.string().optional(),

  // If "patient_pcp" is set to yes, then this item should indicate the PCP's last name.
  pcp_last_name: z.string().optional(),

  // If "patient_pcp" is set to yes, then this item should indicate the PCP's phone number.
  pcp_phone: z.string().optional(),

  // Indicator of whether the patient has a caregiver, defined as a relative, or an unpaid nonrelative, who assists the patient with activities of daily living and/or instrumental activities of daily living.
  // Answer options include: Yes (multiple), Yes (one), No, or Undetermined.
  has_pcg: z.nativeEnum(HasPrimaryCareGiver),

  // If "has_pcg" is set to yes, then this item should indicate the Primary Caregiver's first name.
  pcg_first_name: z.string().optional(),

  // If "has_pcg" is set to yes, then this item should indicate the Primary Caregiver's last name.
  pcg_last_name: z.string().optional(),

  // If "has_pcg" is set to yes, then this item should indicate the Primary Caregiver's street address.
  pcg_address_line: z.string().optional(),

  // If "has_pcg" is set to yes, then this item should indicate the Primary Caregiver's city of residence.
  pcg_address_city: z.string().optional(),

  // If "has_pcg" is set to yes, then this item should indicate the Primary Caregiver's state of residence.
  pcg_address_state: z.string().optional(),

  // If "has_pcg" is set to yes, then this item should indicate the Primary Caregiver's Zip code in the USPS standard five digit format.
  pcg_address_postalCode: z.string().optional(),
  
  // If "has_pcg" is set to yes, then this item should indicate the Primary Caregiver's email address in standard xxxx@yyyyy.com format.
  pcg_email: z.string().optional(),

  // If "has_pcg" is set to yes, then this item should indicate the Primary Caregiver's phone number in the format ###-###-####.
  pcg_phone: z.string().optional(),

  // Description of the type of Primary Caregiver phone number provided. Answer options include: home (landline), business, or mobile.
  pcg_phone_type: z.nativeEnum(PhoneType).optional(),

  // If "has_pcg" is set to yes, then this item should indicate the Primary Caregiver's date of birth. Date format must be numeric YYYY-MM-DD. 
  pcg_date_of_birth: z.string().optional(),

  // If "has_pcg" is set to yes, then this item should indicate the Primary Caregiver's sex.
  // Answer options include: Male, Female, or Unknown. If this information is not disclosed, please select Unknown. 
  pcg_sex: z.nativeEnum(Sex).optional(),

  // If "has_pcg" is set to yes, then this item should indicate the Primary Caregiver's race.
  // This is based on the OMB race categories as defined by the OMB Standards for Maintaining, Collecting, and Presenting Federal Data on Race and Ethnicity, Statistical Policy Directive No. 15, as revised, October 30, 1997.
  // Answer options include: American Indian or Alaska Native, Asian, Black or African American, Native Hawaiian or Other Pacific Islander, White, Asked but unknown, Other, and Unknown.
  // If this information is not disclosed, please select Asked but unknown.
  pcg_race: z.nativeEnum(Race).optional(),

  // If "has_pcg" is set to yes, then this item should indicate the Primary Caregiver’s relationship with the patient.
  // Answer options include: Spouse, Domestic partner, Daughter, Son, Sibling, Other family member, Friend, or Other non-family member.
  pcg_relationship: z.nativeEnum(Relationship).optional(),

  // If "has_pcg" is set to yes, then this item should indicate the Primary Caregiver lives with patient. Answer options include: Yes or No. 
  // should be converted to boolean on form submission
  pcg_residence: z.nativeEnum(YesNo).optional(),

  // If "has_pcg" is set to yes, then this item should indicate the Primary Caregiver is a Medicare beneficiary. Answer options include: Yes or No.
  // should be converted to boolean on form submission
  pcg_medicare_status:  z.nativeEnum(YesNo).optional(),

  // If "pcg_medicare_status" is set to yes, then this item should indicate their Medicare Beneficiary Identifier (MBI) number. MBIs must be 11 characters. The 1st, 4th, 7th, 10th, and 11th characters will always be numbers. The 2nd, 5th, 8th, and 9th characters will always be upper-case letters, except for S, L, O, I, B, and Z. The 3rd and 6th characters will be letters or numbers. 
  pcg_mbi: z.string().optional(),

  // Primary Caregiver's Zarit Burden Interview (ZBI) score based on the 22 item version. The answer should be provided as a whole number 0-88.
  zbi_id: z.string().optional(),

  // If "has_pcg" is set to yes, then this item should indicate the length of time in years the Primary Caregiver has been in the caregiver role.
  // The answer should be provided as a whole number greater or equal to 1. If length of time is unknown, leave this item blank.
  pcg_role_years: z.string().optional(),

  // If "has_pcg" is set to yes, then this item should indicate the length of time in months the Primary Caregiver has been in the caregiver role.
  // The answer should be provided as a whole number between 0 and 11. If length of time is unknown, leave this item blank.
  pcg_role_months: z.string().optional(),

  // Indication of clinician's attestation regarding the patient's dementia status.
  // Answer options include: Yes, the patient meets the National Institute on Aging-Alzheimer’s Association diagnostic guidelines for dementia and/or the DSM-5 diagnostic guidelines for major neurocognitive disorder; 
  // Yes, I received a written report of a documented dementia diagnosis; or, No, I cannot attest to either statement.
  clinician_attestation: z.nativeEnum(ClinicianAttestation),

  // First name of attesting clinician.
  clinician_first_name: z.string(),

  // Clinician's middle name.
  clinician_middle_name: z.string().optional(),

  // Last name of attesting clinician.
  clinician_last_name: z.string(),
  
  // Attesting clinician's National Provider Identification (NPI) number.
  npi: z.string(),
})

export const getCurrentValueForSelect = (field: any) => {
  return {value: field.value, label: field.value}
}

export const getOptionsFromEnum = (enumObj: Record<string, string>) => {
  return Object.values(enumObj).map(value => ({
    label: value,
    value: value,
  }))
}

export const getOptionsForUserAssessments = (userAssessments: Record<string, any>[]) => {
  return userAssessments.map(assessment => {
    const totalScore = assessment.total_score;
    return {
      value: assessment.id,
      label: `${assessment.total_score} ${dayjs(assessment.created_at).format('MM/DD/YYYY')} ${assessment.status}`
    }
  })
}

export const getFirstAssessmentId = (assessments: Record<string, any>[]) => {
  return assessments.length > 0 && assessments[0].id ? assessments[0].id : ""
}

const returnValueIfNotUndefined = (value: any) => {
  if (value === undefined || value === '') return undefined;
  // convert a YesNo enum to a boolean value
  if (Object.values(YesNo).includes(value)) {
    return value === YesNo.YES ? true : false;
  }
  return value;
}

export const formatPAFSubmissionData = (
  data: z.infer<typeof PAFSchema>,
  networkId: string | undefined,
  status: "DRAFT" | "READY",
) => {
  const { 
    assessment_type,
    assessment_date,
    staging_tool,
    fast_id,
    cdr_id,
    promis_id,
    zbi_id,
    id,
    ...rest
  } = data;

  // add extra sanitation for fields that should be booleans
  const assessmentJson = {
    ...rest,
    staging_tool,
    patient_pcp: returnValueIfNotUndefined(rest.patient_pcp),
    pcg_residence: returnValueIfNotUndefined(rest.pcg_residence),
    pcg_medicare_status: returnValueIfNotUndefined(rest.pcg_medicare_status),
  }

  return {
    id: returnValueIfNotUndefined(id),
    network_id: returnValueIfNotUndefined(networkId),
    assessment_type: returnValueIfNotUndefined(assessment_type),
    assessment_date: returnValueIfNotUndefined(assessment_date),
    zbi_id: returnValueIfNotUndefined(zbi_id),
    promis_id: returnValueIfNotUndefined(promis_id),
    staging_id: returnValueIfNotUndefined(staging_tool == "FAST" ? fast_id : cdr_id),
    status: status,
    assessment_json: JSON.stringify(assessmentJson),
  } as PAFSubmissionUpdate;
}

export const generateDefaultFormData = (data: PAFSubmission | undefined): z.infer<typeof PAFSchema> => {
  if (!data) {
    return {
      assessment_type: AssessmentType.INITIAL_ASSESSMENT,
      has_pcg: HasPrimaryCareGiver.NO,
      patient_pcp: YesNo.NO,
      staging_tool: DementiaStageAssessmentTool.FAST,
      phone_type: PhoneType.HOME,
      pcg_residence: YesNo.YES,
      pcg_medicare_status:YesNo.YES,
    } as z.infer<typeof PAFSchema>;
  }
  
  const assessmentJson = typeof data.assessment_json === 'string' ? JSON.parse(data.assessment_json) : data.assessment_json;
  
  const stagingToolValues = {
    staging_tool: assessmentJson.staging_tool,
    staging_id: data.staging_id,
    fast_id: assessmentJson.staging_tool == "FAST" ? data.staging_id : "",
    cdr_id: assessmentJson.staging_tool == "CDR" ? data.staging_id : ""
  }

  const yesNoValues = {
    patient_pcp: assessmentJson.patient_pcp === true ? YesNo.YES : YesNo.NO,
    pcg_residence: assessmentJson.pcg_residence === true ? YesNo.YES : YesNo.NO,
    pcg_medicare_status: assessmentJson.pcg_medicare_status === true ? YesNo.YES : YesNo.NO,
  }

  return {
    ...assessmentJson,
    ...stagingToolValues,
    ...yesNoValues,
    id: data.id,
    assessment_type: data.assessment_type,
    assessment_date: data.assessment_date,
    has_pcg: assessmentJson.has_pcg ? assessmentJson.has_pcg : HasPrimaryCareGiver.NO,
    zbi_id: data.zbi_id,
    promis_id: data.promis_id,
  } as z.infer<typeof PAFSchema>;
}