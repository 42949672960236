import { TextField } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import { ReactComponent as AfternoonIconSvg } from "assets/afternoon-icon.svg";
import { ReactComponent as BedtimeIconSvg } from "assets/bedtime-icon.svg";
import { ReactComponent as CancelIconSvg } from "assets/cancel-icon.svg";
import { ReactComponent as CheckmarkIconSvg } from "assets/checkmark-icon.svg";
import { ReactComponent as EditIconSvg } from "assets/edit-folder.svg";
import { ReactComponent as EveningIconSvg } from "assets/evening-icon.svg";
import { ReactComponent as MorningIconSvg } from "assets/morning-icon.svg";
import {
  useToggleDose,
  useUserPrescriptionsQuery,
} from "backend/resources/userPrescription/userPrescription";
import type { UserPrescriptionDoseTakenInsert } from "backend/resources/userPrescriptionDoseTaken/userPrescriptionDoseTaken";
import {
  combineScheduledAndTakenDoses,
  useDeleteUserPrescriptionDoseTaken,
  useDosesTakenQuery,
  useUpsertUserPrescriptionDoseTaken,
} from "backend/resources/userPrescriptionDoseTaken/userPrescriptionDoseTaken";
import type { ScheduledDoseInformation, UserPrescriptionScheduledDoseInsert } from "backend/resources/userPrescriptionScheduledDose/userPrescriptionScheduledDose";
import { useDeleteScheduledDoses, useScheduledDosesQuery, useUpsertScheduledDoses } from "backend/resources/userPrescriptionScheduledDose/userPrescriptionScheduledDose";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { LoadingSpinner } from "components/LoadingSpinner";
import dayjs from "dayjs";
import { useWindowSize } from "hooks/useWindowSize";
import { Arrow } from "icons/Arrow/Arrow";
import { CarePilotRoute, createPath, useAppNavigate } from "lib/routing";
import { useEffect, useMemo, useState } from "react";
import { ResponsiveModal } from "shared/ui/responsive-modal";
import { useMedicationStore } from "state/medicationStore";
import { useUserStore } from "state/user";
import { capitalizeFirstLetter } from "utils";

export type ScheduleSectionKey =
  | "morning"
  | "afternoon"
  | "evening"
  | "bedtime";

export type ScheduleSectionDetails = {
  title: string;
  icon: JSX.Element | null;
  timeString: string;
};

export function getCurrentMedicationSection(): ScheduleSectionKey {
  const now = dayjs();
  const currentHour = now.hour();

  if (currentHour >= 8 && currentHour < 12) {
    return "morning";
  } else if (currentHour >= 12 && currentHour < 17) {
    return "afternoon";
  } else if (currentHour >= 17 && currentHour < 21) {
    return "evening";
  } else {
    return "bedtime";
  }
}

export const sectionDetails: Record<
  ScheduleSectionKey,
  ScheduleSectionDetails
> = {
  morning: {
    title: "Morning",
    icon: <MorningIconSvg className="w-12 h-12" />,
    timeString: "5-12",
  },
  afternoon: {
    title: "Afternoon",
    icon: <AfternoonIconSvg className="w-12 h-12" />,
    timeString: "12-5",
  },
  evening: {
    title: "Evening",
    icon: <EveningIconSvg className="w-12 h-12" />,
    timeString: "5-9",
  },
  bedtime: {
    title: "Bedtime",
    icon: <BedtimeIconSvg className="w-12 h-12" />,
    timeString: "9",
  },
};

// Move the function outside of components to be reusable
function createUserPrescriptionDoseTakenRow(
  scheduledDoseInfo: ScheduledDoseInformation,
  authUser: any,
  selectedScheduleDate: string,
  dosesTaken: any[]
) {
  const now = dayjs();
  
  // Get count of existing doses taken for this drug on this date
  const existingDosesCount = dosesTaken?.filter(
    dose => dose.user_prescription_scheduled_dose_id === scheduledDoseInfo.user_prescription_scheduled_dose_id
  ).length ?? 1;
  
  // Add a small offset (1 second) for each existing dose to ensure uniqueness
  const timeWithOffset = now.add(existingDosesCount, 'second');

  const userPrescriptionDoseTaken: UserPrescriptionDoseTakenInsert = {
    dose_taken: scheduledDoseInfo.dose_count ?? 1,
    taken_at: timeWithOffset.toISOString(),
    taken_at_local: timeWithOffset.format('YYYY-MM-DD HH:mm:ss'),
    taken_at_local_date: selectedScheduleDate,
    user_prescription_scheduled_dose_id:
      scheduledDoseInfo.user_prescription_scheduled_dose_id,
    user_id: authUser?.id,
    user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  return userPrescriptionDoseTaken;
}

export function ScheduleView() {
  const authUser = useUserStore((state) => state.user);
  const { isMobile } = useWindowSize();
  const navigate = useAppNavigate();

  const { isLoadingUserPrescriptions } = useUserPrescriptionsQuery();
  const { asNeededScheduledDoses, isLoadingScheduledDoses } = useScheduledDosesQuery(authUser?.id);

  // Group as-needed doses by drug name
  const dosesByDrug = useMemo(() => 
    asNeededScheduledDoses?.reduce((acc, dose) => {
      const drugName = dose.drug_name_desc || 'Unknown';
      acc[drugName] = [...(acc[drugName] ?? []), dose];
      return acc;
    }, {} as Record<string, ScheduledDoseInformation[]>) ?? {}
  , [asNeededScheduledDoses]);

  const isLoading = isLoadingUserPrescriptions || isLoadingScheduledDoses;

  // Render loading state
  if (isLoading) {
    return (
      <div className="flex w-full h-full items-center justify-center">
        <LoadingSpinner className="w-20 h-20" />
      </div>
    );
  }

  return (
    <div className="flex flex-col pb-40 md:min-w-[800px] md:pb-36">
      <div className="w-full md:max-w-[600px]">
        {/* Table Header */}
        <TableHeader />

        {/* Accordion Groups */}
        <div className="flex flex-col">
          {Object.entries(dosesByDrug).map(([drugName, doses]) => (
            <DrugAccordion 
              key={drugName} 
              drugName={drugName} 
              doses={doses}
            />
          ))}
        </div>
      </div>

      <TotalsTaken />
    </div>
  );
}

function TableHeader() {
  return (
    <div className="grid grid-cols-[3fr,1.5fr,1fr,2fr,1fr,0.5fr,0.5fr] gap-4 px-4 py-3">
      <div className="font-medium self-end">Name</div>
      <div className="font-medium self-end">Doctor Notes</div>
      <div className="font-medium text-center self-end">Taken</div>
      <div className="font-medium text-center self-end">Time</div>
      <div className="font-medium text-center self-end">Quantity<br />Taken</div>
      <div className="self-end"></div>
      <div className="self-end"></div>
    </div>
  );
}

function DrugAccordion({ drugName, doses }: { 
  drugName: string; 
  doses: ScheduledDoseInformation[];
}) {
  const [isOpen, setIsOpen] = useState(false);
  
  const authUser = useUserStore((state) => state.user);
  const upsertScheduledDoses = useUpsertScheduledDoses().mutateAsync;
  const { userPrescriptions } = useUserPrescriptionsQuery();

  const handleAddAnother = async () => {
    const baseDose = doses[0];
    
    // Create a new scheduled dose using the existing upsert function
    const scheduledDoseInsert: UserPrescriptionScheduledDoseInsert = {
      user_id: authUser?.id,
      user_prescription_id: baseDose.user_prescription_id,
      as_needed: true,
      dose_count: baseDose.dose_count,
      dose_time: baseDose.dose_time,
      max_doses_per_day: baseDose.max_doses_per_day
    };

    const { data, error } = await upsertScheduledDoses([scheduledDoseInsert]);
  };

  // Check if this drug is high risk by matching the prescription
  const isHighRisk = useMemo(() => {
    if (!userPrescriptions || !doses[0]?.user_prescription_id) return false;
    
    const prescription = userPrescriptions.find(
      p => p.id === doses[0].user_prescription_id
    );
    return prescription?.is_hrrx ?? false;
  }, [userPrescriptions, doses]);

  return (
    <div className="border-b border-gray-200">
      <div className="flex items-center p-4 w-full">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="flex items-center gap-2 flex-grow text-left"
        >
          <Arrow
            size={16}
            fill="#f97316"
            className={`transform transition-transform ${isOpen ? 'rotate-180' : 'rotate-90'}`}
          />
          <div className="flex items-center gap-2">
            <span className="font-medium">
              {capitalizeFirstLetter(drugName)}
            </span>
            {isHighRisk && (
              <ButtonWithIcon 
                text="" 
                icon={IconOption.WARNING} 
                size="extra_small" 
                onClick={() => {}} 
                className="cursor-default"
              />
            )}
          </div>
        </button>
      </div>
      
      {isOpen && (
        <div className="pb-4">
          {doses.map((dose, index) => (
            <ScheduleSection
              key={`${dose.id}-${dose.user_prescription_id}-${index}`}
              scheduledDoseInfo={dose}
              doseNumber={index + 1}
            />
          ))}
          
          <button
            onClick={handleAddAnother}
            className="mt-2 text-orange-500 hover:text-orange-600 flex items-center gap-2 pl-8"
          >
            <span className="text-lg">+</span> Add another
          </button>
        </div>
      )}
    </div>
  );
}

export function ScheduleSection({
  scheduledDoseInfo,
  doseNumber,
}: {
  scheduledDoseInfo: ScheduledDoseInformation;
  doseNumber: number;
}) {
  const authUser = useUserStore((state) => state.user);
  const selectedScheduleDate = useMedicationStore(state => state.selectedScheduleDate);
  const { dosesTaken } = useDosesTakenQuery(authUser?.id, selectedScheduleDate);
  const navigate = useAppNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const deleteScheduledDoses = useDeleteScheduledDoses().mutateAsync;
  const [isEditing, setIsEditing] = useState(false);
  const [modifiedDoseTaken, setModifiedDoseTaken] = useState<number | null>();
  const [modifiedTakenAtTime, setModifiedTakenAtTime] = useState<dayjs.Dayjs | null>(null);
  const upsertUserPrescriptionDoseTaken = useUpsertUserPrescriptionDoseTaken().mutateAsync;
  const { toggleDose } = useToggleDose();

  const doseTakenInfo = dosesTaken?.find(
    (doseTaken) => doseTaken.user_prescription_scheduled_dose_id === scheduledDoseInfo.user_prescription_scheduled_dose_id
  );

  // Add this useEffect to handle state updates when dose info changes
  useEffect(() => {
    if (doseTakenInfo) {
      setModifiedDoseTaken(doseTakenInfo.dose_taken);
      setModifiedTakenAtTime(dayjs(doseTakenInfo?.taken_at));
    }
  }, [doseTakenInfo]);

  // Reset state function
  function resetState() {
    setIsEditing(false);
    setModifiedDoseTaken(doseTakenInfo?.dose_taken || 0);
    setModifiedTakenAtTime(dayjs(doseTakenInfo?.taken_at));
  }

  const handleDelete = async () => {
    if (!scheduledDoseInfo.user_prescription_scheduled_dose_id) return;
    await deleteScheduledDoses([scheduledDoseInfo.user_prescription_scheduled_dose_id]);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  async function updateUserPrescriptionDoseTakenRow() {
    const userPrescriptionDoseTaken: UserPrescriptionDoseTakenInsert = {
      dose_taken: modifiedDoseTaken || doseTakenInfo?.dose_taken || 1,
      taken_at: modifiedTakenAtTime?.toISOString() || dayjs().toISOString(),
      taken_at_local: modifiedTakenAtTime?.format('YYYY-MM-DD HH:mm:ss') || dayjs().format('YYYY-MM-DD HH:mm:ss'),
      taken_at_local_date: selectedScheduleDate,
      user_prescription_scheduled_dose_id: scheduledDoseInfo.user_prescription_scheduled_dose_id,
      user_id: authUser?.id,
      user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    const { data, error } = await upsertUserPrescriptionDoseTaken(userPrescriptionDoseTaken);
    setIsEditing(false);

    // Calculate the difference between the modified dose and the original dose
    const doseDelta = (modifiedDoseTaken || 0) - (doseTakenInfo?.dose_taken || 0);

    // Update the doses_remaining with the calculated doseDelta
    toggleDose({
      scheduledDoseInfo,
      shouldIncrementDosesRemaining: doseDelta <= 0,
      doseTakenAmount: Math.abs(doseDelta),
    });
  }

  return (
    <div className="grid grid-cols-[3fr,1.5fr,1fr,2fr,1fr,0.5fr,0.5fr] gap-4 py-2 hover:bg-gray-50">
      {/* Name */}
      <div className="flex items-center">
        <span className="text-gray-600 pl-8">
          Dose {doseNumber}
        </span>
      </div>

      {/* Doctor Notes */}
      <div className="flex justify-center">
        <ButtonWithIcon
          text=""
          icon={IconOption.NOTES}
          size="small"
          onClick={() => setIsOpen(true)}
          className="hover:bg-gray-100 rounded-full"
        />
        <ResponsiveModal
          isOpen={isOpen}
          onClose={handleClose}
          title={scheduledDoseInfo.drug_name_desc}
          closeText="Close"
          footerButtons={
            <ButtonWithIcon
              onClick={handleClose}
              text="Close"
              size="small"
              icon={IconOption.CHECKMARK}
            />
          }
        >
          <div className="flex flex-col gap-4">
            <ul className="list-disc pl-4 space-y-2">
              {scheduledDoseInfo.doctor_comments?.split('\n').map((comment, index) => (
                <li key={index}>{comment}</li>
              ))}
            </ul>
          </div>
        </ResponsiveModal>
      </div>

      {/* Taken */}
      <div className="flex justify-center">
        <TakenButton
          scheduledDoseInfo={scheduledDoseInfo}
          doseTakenId={doseTakenInfo?.user_prescription_dose_taken_id}
        />
      </div>

      {/* Time */}
      <div className="flex justify-center">
        <TakenAtCell
          isEditing={isEditing}
          takenAt={doseTakenInfo?.taken_at_local}
          modifiedTakenAtTime={modifiedTakenAtTime}
          setModifiedTakenAtTime={setModifiedTakenAtTime}
        />
      </div>

      {/* Quantity */}
      <div className="flex justify-center">
        {isEditing ? (
          <TextField
            size="small"
            className="max-w-[8ch] text-sm"
            value={modifiedDoseTaken?.toString() || doseTakenInfo?.dose_taken?.toString() || "0"}
            type="number"
            onKeyDown={(e) => {
              if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
                e.preventDefault();
              }
            }}
            onChange={(event) => {
              const value = Number(event.target.value);
              if (value > 100) {
                setModifiedDoseTaken(100);
              } else if (value < 0) {
                setModifiedDoseTaken(0);
              } else {
                setModifiedDoseTaken(value);
              }
            }}
          />
        ) : (
          <div className="flex items-center">
            {doseTakenInfo?.dose_taken || (
              <span className="text-gray-400">--</span>
            )}
          </div>
        )}
      </div>

      {/* Edit button */}
      <div className="flex justify-center">
        {doseTakenInfo?.dose_taken === undefined ? (
          <div />
        ) : isEditing ? (
          <div className="flex h-full items-center gap-1">
            <button onClick={updateUserPrescriptionDoseTakenRow}>
              <CheckmarkIconSvg className="w-6" />
            </button>
            <button onClick={resetState}>
              <CancelIconSvg className="w-6" />
            </button>
          </div>
        ) : (
          <button onClick={() => setIsEditing(true)}>
            <EditIconSvg className="w-6 h-6" />
          </button>
        )}
      </div>

      {/* Delete button */}
      <div className="flex justify-center">
        <ButtonWithIcon
          icon={IconOption.TRASH}
          text=""
          onClick={handleDelete}
          className="text-red-500 hover:text-red-600"
        />
      </div>
    </div>
  );
}

export function ScheduledDoseRow({
  scheduledDoseInfo,
}: {
  scheduledDoseInfo: ScheduledDoseInformation;
}) {
  const authUser = useUserStore((state) => state.user);

  const { toggleDose } = useToggleDose();

  const selectedScheduleDate = useMedicationStore(
    (state) => state.selectedScheduleDate
  );

  const { isLoadingDosesTaken, dosesTaken, refetchDosesTaken } =
    useDosesTakenQuery(authUser?.id, selectedScheduleDate);

  if (!scheduledDoseInfo.id) {
    return <div>Error retrieving medication</div>;
  }

  // pull out dose taken information
  const doseTakenInfo = dosesTaken?.find(
    (doseTaken) =>
      doseTaken.user_prescription_scheduled_dose_id ===
      scheduledDoseInfo.user_prescription_scheduled_dose_id
  );

  const doseCount = scheduledDoseInfo.dose_count
    ? scheduledDoseInfo.dose_count
    : scheduledDoseInfo.max_doses_per_day
      ? scheduledDoseInfo.max_doses_per_day
      : "0";

  // editing state
  const [isEditing, setIsEditing] = useState(false);

  const [modifiedDoseTaken, setModifiedDoseTaken] = useState<number | null>();

  const [modifiedTakenAtTime, setModifiedTakenAtTime] =
    useState<dayjs.Dayjs | null>(dayjs(doseTakenInfo?.taken_at));

  // reset state when editing is toggled
  function resetState() {
    setIsEditing(false);
    setModifiedDoseTaken(doseTakenInfo?.dose_taken || 0);
    setModifiedTakenAtTime(dayjs(doseTakenInfo?.taken_at));
  }

  // update dose taken
  const upsertUserPrescriptionDoseTaken =
    useUpsertUserPrescriptionDoseTaken().mutateAsync;

  async function updateUserPrescriptionDoseTakenRow() {
    const { data, error } = await upsertUserPrescriptionDoseTaken(
      createUserPrescriptionDoseTakenRow(scheduledDoseInfo, authUser, selectedScheduleDate, dosesTaken ?? [])
    );
    setIsEditing(false);

    // Calculate the difference between the modified dose and the original dose
    const doseDelta = (modifiedDoseTaken || 0) - (doseTakenInfo?.dose_taken || 0);

    // Update the doses_remaining with the calculated doseDelta
    toggleDose({
      scheduledDoseInfo,
      shouldIncrementDosesRemaining: doseDelta <= 0,
      doseTakenAmount: Math.abs(doseDelta),
    });
  }

  // keep doses in sync with selected schedule date
  useEffect(() => {
    refetchDosesTaken();
  }, [selectedScheduleDate]);

  // ensure default modified dose taken is set when dose taken info loads
  useEffect(() => {
    if (doseTakenInfo) {
      setModifiedDoseTaken(doseTakenInfo.dose_taken);
      setModifiedTakenAtTime(dayjs(doseTakenInfo?.taken_at));
    }
  }, [doseTakenInfo]);

  return (
    <div className="flex flex-col gap-3">
      <div className="grid grid-cols-[2fr,1fr,1fr,1fr,1.5fr,1fr,1fr] items-center min-h-[40px] pr-5">
        {/* name */}
        <a
          href={
            createPath({
              path: CarePilotRoute.DAY_PRESCRIPTION,
              params: {
                dispensable_drug_id: scheduledDoseInfo.dispensable_drug_id || ""
              }
            })
          }
          className="flex flex-grow items-center py-2 gap-2 border-zinc-100 relative cursor-pointer hover:underline">
          {capitalizeFirstLetter(scheduledDoseInfo.drug_name_desc || "")}
        </a>
        {/* dosage */}
        <div className="flex items-center justify-center">
          {doseCount}{" "}
          {/* {lowercaseFirstLetter(scheduledDoseInfo.dose_form_desc || "dose")}
          {"(s)"} */}
        </div>
        {/* food */}
        <div className="flex items-center justify-center">
          {scheduledDoseInfo.take_with_food ? "Yes" : "No"}
        </div>
        {/* taken? */}
        <div className="flex items-center justify-center">
          {isLoadingDosesTaken ? (
            <LoadingSpinner className="w-5 h-5" />
          ) : (
            <TakenButton
              scheduledDoseInfo={scheduledDoseInfo}
              doseTakenId={doseTakenInfo?.user_prescription_dose_taken_id}
            />
          )}
        </div>
        {/* taken at */}
        <div className="flex justify-center">
          {isLoadingDosesTaken ? (
            <LoadingSpinner className="w-5 h-5" />
          ) : (
            <TakenAtCell
              isEditing={isEditing}
              takenAt={doseTakenInfo?.taken_at_local}
              modifiedTakenAtTime={modifiedTakenAtTime}
              setModifiedTakenAtTime={setModifiedTakenAtTime}
            />
          )}
        </div>
        {/* dose taken */}
        <div className="flex justify-center">
          {isLoadingDosesTaken ? (
            <LoadingSpinner className="w-5 h-5" />
          ) : (
            <DoseTakenCell
              doseTaken={doseTakenInfo?.dose_taken}
              doseFormDesc={scheduledDoseInfo.dose_form_desc || "dose"}
              isEditing={isEditing}
              modifiedDoseTaken={modifiedDoseTaken}
              setModifiedDoseTaken={setModifiedDoseTaken}
            />
          )}
        </div>
        {/* edit button */}
        <div className="flex justify-center">
          {doseTakenInfo?.dose_taken === undefined ? (
            <div />
          ) : isEditing ? (
            <div className="flex h-full items-center gap-1">
              <button
                onClick={() => {
                  updateUserPrescriptionDoseTakenRow();
                }}>
                <CheckmarkIconSvg className="w-6" />
              </button>
              <button
                onClick={() => {
                  resetState();
                }}>
                <CancelIconSvg className="w-6" />
              </button>
            </div>
          ) : (
            <button
              onClick={() => {
                setIsEditing(true);
              }}>
              <EditIconSvg className="w-6 h-6" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export function ScheduledDoseRowMobile({
  scheduledDoseInfo,
}: {
  scheduledDoseInfo: ScheduledDoseInformation;
}) {
  const authUser = useUserStore((state) => state.user);

  const selectedScheduleDate = useMedicationStore(
    (state) => state.selectedScheduleDate
  );

  const { isLoadingDosesTaken, dosesTaken, refetchDosesTaken } =
    useDosesTakenQuery(authUser?.id, selectedScheduleDate);

  if (!scheduledDoseInfo.id) {
    return <div>Error retrieving medication</div>;
  }

  // pull out dose taken information
  const doseTakenInfo = dosesTaken?.find(
    (doseTaken) =>
      doseTaken.user_prescription_scheduled_dose_id ===
      scheduledDoseInfo.user_prescription_scheduled_dose_id
  );

  const doseCount = scheduledDoseInfo.dose_count
    ? scheduledDoseInfo.dose_count
    : scheduledDoseInfo.max_doses_per_day
      ? scheduledDoseInfo.max_doses_per_day
      : "0";

  // editing state
  const [isEditing, setIsEditing] = useState(false);

  const [modifiedDoseTaken, setModifiedDoseTaken] = useState<number | null>();

  const [modifiedTakenAtTime, setModifiedTakenAtTime] =
    useState<dayjs.Dayjs | null>(dayjs(doseTakenInfo?.taken_at));

  // keep doses in sync with selected schedule date
  useEffect(() => {
    refetchDosesTaken();
  }, [selectedScheduleDate]);

  // ensure default modified dose taken is set when dose taken info loads
  useEffect(() => {
    if (doseTakenInfo) {
      setModifiedDoseTaken(doseTakenInfo.dose_taken);
      setModifiedTakenAtTime(dayjs(doseTakenInfo?.taken_at));
    }
  }, [doseTakenInfo]);

  return (
    <div className="flex flex-col gap-3">
      <div className="grid grid-cols-[2fr,1fr,1fr,1fr] items-center min-h-[40px]">
        {/* name */}
        <a
          href={
            createPath({
              path: CarePilotRoute.DAY_PRESCRIPTION,
              params: {
                dispensable_drug_id: scheduledDoseInfo.dispensable_drug_id || ""
              }
            })
          }
          className="flex flex-grow items-center py-2 gap-2 border-zinc-100 relative cursor-pointer hover:underline">
          {capitalizeFirstLetter(scheduledDoseInfo.drug_name_desc || "")}
        </a>
        {/* dosage */}
        <div className="flex items-center justify-center">{doseCount}</div>
        {/* taken? */}
        <div className="flex items-center justify-center">
          {isLoadingDosesTaken ? (
            <LoadingSpinner className="w-5 h-5" />
          ) : (
            <TakenButton
              scheduledDoseInfo={scheduledDoseInfo}
              doseTakenId={doseTakenInfo?.user_prescription_dose_taken_id}
            />
          )}
        </div>
        {/* taken at */}
        <div className="flex justify-center">
          {isLoadingDosesTaken ? (
            <LoadingSpinner className="w-5 h-5" />
          ) : (
            <TakenAtCell
              isEditing={isEditing}
              takenAt={doseTakenInfo?.taken_at_local}
              modifiedTakenAtTime={modifiedTakenAtTime}
              setModifiedTakenAtTime={setModifiedTakenAtTime}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export function TakenButton({
  scheduledDoseInfo,
  doseTakenId,
}: {
  scheduledDoseInfo: ScheduledDoseInformation;
  doseTakenId?: string | null;
}) {
  const authUser = useUserStore((state) => state.user);
  const selectedScheduleDate = useMedicationStore((state) => state.selectedScheduleDate);
  const { dosesTaken } = useDosesTakenQuery(authUser?.id, selectedScheduleDate);

  const { toggleDose } = useToggleDose();
  const upsertUserPrescriptionDoseTaken = useUpsertUserPrescriptionDoseTaken().mutateAsync;
  const deleteUserPrescriptionDoseTaken = useDeleteUserPrescriptionDoseTaken().mutateAsync;

  function createUserPrescriptionDoseTakenRow() {
    const now = dayjs();
    
    const existingDosesCount = dosesTaken?.filter(
      dose => dose.user_prescription_scheduled_dose_id === scheduledDoseInfo.user_prescription_scheduled_dose_id
    ).length ?? 1;
    
    const timeWithOffset = now.add(existingDosesCount, 'second');

    const userPrescriptionDoseTaken: UserPrescriptionDoseTakenInsert = {
      dose_taken: scheduledDoseInfo.dose_count ?? 1,
      taken_at: timeWithOffset.toISOString(),
      taken_at_local: timeWithOffset.format('YYYY-MM-DD HH:mm:ss'),
      taken_at_local_date: selectedScheduleDate,
      user_prescription_scheduled_dose_id:
        scheduledDoseInfo.user_prescription_scheduled_dose_id,
      user_id: authUser?.id,
      user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    return userPrescriptionDoseTaken;
  }

  const handleUntakeDose = async () => {
    if (!doseTakenId) return;
    await deleteUserPrescriptionDoseTaken(doseTakenId);
    toggleDose({
      scheduledDoseInfo,
      shouldIncrementDosesRemaining: true,
      doseTakenAmount: scheduledDoseInfo.dose_count ?? 1,
    });
  };

  if (doseTakenId) {
    return (
      <ButtonWithIcon
        icon={IconOption.CHECKMARK}
        text=""
        className="h-8 w-8 rounded-full bg-emerald-50 text-emerald-600 !shadow-none flex items-center justify-center [&>svg]:h-5 [&>svg]:w-5"
        onClick={handleUntakeDose}
      />
    );
  } else {
    return (
      <button
        className="w-8 h-8 rounded-full border-2 border-gray-300 flex items-center justify-center"
        onClick={async () => {
          const { data, error } = await upsertUserPrescriptionDoseTaken(
            createUserPrescriptionDoseTakenRow()
          );
          toggleDose({
            scheduledDoseInfo,
            shouldIncrementDosesRemaining: false,
            doseTakenAmount: scheduledDoseInfo.dose_count || 1,
          });
        }}
      >
        {/* Empty circle for untaken state */}
      </button>
    );
  }
}

export function TakenAtCell({
  textSize,
  isEditing,
  takenAt,
  modifiedTakenAtTime,
  setModifiedTakenAtTime,
}: {
  textSize?: string;
  isEditing: boolean;
  takenAt?: string | null;
  modifiedTakenAtTime: dayjs.Dayjs | null;
  setModifiedTakenAtTime: React.Dispatch<
    React.SetStateAction<dayjs.Dayjs | null>
  >;
}) {
  if (isEditing) {
    return (
      <TimePicker
        ampm={true}
        className={`max-w-[14ch] ${textSize || ""}`}
        onChange={(newValue: dayjs.Dayjs | null) =>
          setModifiedTakenAtTime(newValue)
        }
        slotProps={{ textField: { size: "small" } }}
        value={modifiedTakenAtTime}
      />
    );
  }
  if (!takenAt) {
    return <div className="flex h-full items-center">N/A</div>;
  } else if (takenAt && !isEditing) {
    return (
      <div className="flex h-full items-center">
        {new Date(takenAt).toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })}
      </div>
    );
  } else {
    return (
      <TimePicker
        ampm={true}
        className="max-w-[14ch]"
        onChange={(newValue: dayjs.Dayjs | null) =>
          setModifiedTakenAtTime(newValue)
        }
        slotProps={{ textField: { size: "small" } }}
        value={modifiedTakenAtTime}
      />
    );
  }
}

export function DoseTakenCell({
  isEditing,
  doseTaken,
  doseFormDesc,
  modifiedDoseTaken,
  setModifiedDoseTaken,
}: {
  isEditing: boolean;
  doseTaken?: number | null;
  doseFormDesc: string;
  modifiedDoseTaken?: number | null;
  setModifiedDoseTaken: React.Dispatch<
    React.SetStateAction<number | null | undefined>
  >;
}) {
  if (doseTaken === undefined) {
    return <div className="flex justify-center">N/A</div>;
  } else if (doseTaken !== undefined && !isEditing) {
    return (
      <div className="flex relative h-full">
        {`${doseTaken} ${doseFormDesc}(s)`}
      </div>
    );
  } else {
    return (
      <TextField
        size="small"
        className="max-w-[8ch] text-sm"
        value={modifiedDoseTaken?.toString() || "0"}
        type="number"
        onKeyDown={(e) => {
          if (
            e.key === "e" ||
            e.key === "E" ||
            e.key === "-" ||
            e.key === "+"
          ) {
            e.preventDefault();
          }
        }}
        onChange={(event) => {
          const value = Number(event.target.value);
          if (value > 100) {
            setModifiedDoseTaken(100);
          } else if (value < 0) {
            setModifiedDoseTaken(0);
          } else {
            setModifiedDoseTaken(value);
          }
        }}
      />
    );
  }
}

export function TotalsTaken() {
  const authUser = useUserStore((state) => state.user);
  const { isMobile } = useWindowSize();
  const selectedScheduleDate = useMedicationStore((state) => state.selectedScheduleDate);
  const { allScheduledDoses } = useScheduledDosesQuery(authUser?.id);
  const { isLoadingDosesTaken, dosesTaken } = useDosesTakenQuery(authUser?.id, selectedScheduleDate);
  const { userPrescriptions } = useUserPrescriptionsQuery();

  const dosesSumByDrug = combineScheduledAndTakenDoses(allScheduledDoses, dosesTaken);

  // Create a map of high risk medications
  const highRiskMeds = useMemo(() => {
    if (!userPrescriptions) return new Map<string, boolean>();
    
    return new Map(
      userPrescriptions.map(prescription => [
        prescription.dispensable_drug_id,
        prescription.is_hrrx ?? false
      ])
    );
  }, [userPrescriptions]);

  return (
    <div className={`flex flex-col px-4 mt-8 ${isMobile ? 'w-full' : 'max-w-[600px]'}`}>
      <h2 className="font-light text-2xl mb-4">Totals</h2>
      <div className="flex flex-col w-[400px]">
        <div className="grid grid-cols-[3fr,1fr] gap-4 mb-2">
          <div className="font-medium">Name</div>
          <div className="font-medium text-right">Quantity<br />Taken</div>
        </div>

        {isLoadingDosesTaken ? (
          <LoadingSpinner className="w-20 h-20" />
        ) : (
          Object.entries(dosesSumByDrug).map(([drugName, drugTotalsInfo]) => {
            // Find the prescription for this drug to check if it's high risk
            const prescription = userPrescriptions?.find(
              p => p.routed_dose_form_drug_desc === drugName
            );
            const isHighRisk = prescription?.is_hrrx ?? false;

            return (
              <div key={drugName} className="grid grid-cols-[3fr,1fr] gap-4 py-1">
                <div className="flex items-center">
                  {capitalizeFirstLetter(drugName)}
                  {isHighRisk && (
                    <ButtonWithIcon 
                      text="" 
                      icon={IconOption.WARNING} 
                      size="extra_small" 
                      onClick={() => {}} 
                      className="ml-2 cursor-default"
                    />
                  )}
                </div>
                <div className="text-right">
                  {drugTotalsInfo.taken}
                </div>
              </div>
            );
          })
        )}

        {/* Update the legend */}
        <div className="flex items-center gap-2 mt-4 text-sm text-gray-600">
          <ButtonWithIcon 
            text="" 
            icon={IconOption.WARNING} 
            size="extra_small" 
            onClick={() => {}} 
            className="cursor-default"
          />
          <span>HRRx</span>
        </div>
      </div>
    </div>
  );
}
