/**
 * @fileoverview Sets up the Zustand assessment store.
 * This store holds information about the GPT side bar.
 */
import create from "zustand";

export type SideBarPageType =
  | "mobilePage"
  | "homePage"
  | "newAssessmentPage"
  | "myLibraryPage"
  | "todosPage"
  | "recommendationConversationPage"
  | "interventionConversationPage"
  | "userUpdate"
  | "carespace"
  | "userUpdatePage"
  | "nanasDayPage"
  | "localSearchPage"
  | "educationPage"
  | "discussionsPage"
  | "myCarePage"
  | "servicesPage"
  | "adminPage"
  | "serviceRequest"
  | "serviceRequestExternal"
  | "service"
  | "serviceExternal"
  | "private";

export enum ChatMessageType {
  USER_MESSAGE = "user_message",
  ARE_YOU_SURE = "are_you_sure",
}

export type SendToGptRequest = {
  conversationId: string;
  text?: string;
  message_type: ChatMessageType;
};

type State = {
  isOpen: boolean;
  type?: SideBarPageType;
  userAssessmentChatGptRecommendationId?: string | undefined | null;
  userRecommendationCategoryName?: string | null | undefined;
  userAssessmentChatGptInterventionId?: string | undefined | null;
  userAssessmentChatGptInterventionResourceId?: string | undefined | null;
  conversationId?: string;
  streamingMessage?: string | null;
  pendingMessage?: boolean;
  lastMessageTime?: number | null;
  inputPlaceholderText?: string | null;
  shouldDisableInput: boolean;
  userMessage?: string;
  messageError?: string | null;
  sendToGptRequest?: SendToGptRequest | null;
};

type Actions = {
  setIsOpen: (isOpen: boolean) => void;
  setType: (type: SideBarPageType) => void;
  setUserAssessmentChatGptRecommendationId: (
    userAssessmentChatGptRecommendationId?: string | undefined | null
  ) => void;
  setRecommendationCategoryName: (
    recommendationCategoryName?: string | null | undefined
  ) => void;
  setUserAssessmentChatGptInterventionId: (
    userAssessmentChatGptInterventionId?: string | undefined | null
  ) => void;
  setUserAssessmentChatGptInterventionResourceId: (
    userAssessmentChatGptInterventionResourceId?: string
  ) => void;
  setConversationId: (conversationId: string) => void;
  setStreamingMessage: (streamingMessage?: string | null) => void;
  setPendingMessage: (pendingMessage: boolean) => void;
  setLastMessageTime: (lastMessageTime: number) => void;
  setInputPlaceholderText: (inputPlaceholderText?: string | null) => void;
  setShouldDisableInput: (shouldDisableInput: boolean) => void;
  setUserMessage: (userMessage: string) => void;
  setMessageError: (messageError?: string) => void;
  setSendToGptRequest: (sendToGptRequest?: SendToGptRequest | null) => void;
  reset: () => void;
};

const initialState: State = {
  isOpen: false,
  type: undefined,
  userAssessmentChatGptRecommendationId: undefined,
  userRecommendationCategoryName: undefined,
  userAssessmentChatGptInterventionId: undefined,
  userAssessmentChatGptInterventionResourceId: undefined,
  conversationId: undefined,
  streamingMessage: undefined,
  lastMessageTime: undefined,
  shouldDisableInput: false,
  userMessage: undefined,
  messageError: undefined,
  sendToGptRequest: undefined,
};

/**
 * Hook to access the GPT store
 */
export const useGptStore = create<State & Actions>()((set, get) => ({
  /**
   * State
   */
  ...initialState,

  /**
   * Actions
   */
  setIsOpen: (isOpen: boolean) => set({ isOpen }),

  setType: (type: SideBarPageType) => set({ type }),

  setUserAssessmentChatGptRecommendationId: (
    userAssessmentChatGptRecommendationId: string | undefined | null
  ) => set({ userAssessmentChatGptRecommendationId }),

  setRecommendationCategoryName: (
    recommendationCategoryName: string | undefined | null
  ) => set({ userRecommendationCategoryName: recommendationCategoryName }),

  setUserAssessmentChatGptInterventionId: (
    userAssessmentChatGptInterventionId?: string | null
  ) => set({ userAssessmentChatGptInterventionId }),

  setUserAssessmentChatGptInterventionResourceId: (
    userAssessmentChatGptInterventionResourceId?: string
  ) => set({ userAssessmentChatGptInterventionResourceId }),

  setConversationId: (conversationId: string) => set({ conversationId }),

  setStreamingMessage: (streamingMessage?: string | null) => {
    if (streamingMessage === null) {
      set({ streamingMessage: null });
    } else {
      set({
        streamingMessage: `${get().streamingMessage || ""}${streamingMessage}`,
      });
    }
  },

  setPendingMessage: (pendingMessage) => set({ pendingMessage }),

  setLastMessageTime: (lastMessageTime) => set({ lastMessageTime }),

  setInputPlaceholderText: (inputPlaceholderText) =>
    set({ inputPlaceholderText }),

  setShouldDisableInput: (shouldDisableInput) =>
    set({ shouldDisableInput }),

  setUserMessage: (userMessage) => set({ userMessage }),

  setMessageError: (messageError) => set({ messageError }),

  setSendToGptRequest: (sendToGptRequest) => set({ sendToGptRequest }),

  reset: () => set(initialState),
}));
