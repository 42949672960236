// intake view
import { Popover } from "@mui/material";
import { ReactComponent as InteractionsIconSvg } from "assets/interactions-icon.svg";
import { ReactComponent as ModerateInteractionIconSvg } from "assets/moderate-interaction.svg";
import { ReactComponent as RefillIconSvg } from "assets/refill-icon.svg";
import { ReactComponent as SeriousInteractionIconSvg } from "assets/serious-interaction.svg";
import { ReactComponent as SevereInteractionIconSvg } from "assets/severe-interaction.svg";
import { useDrugScreenQuery } from "backend/functions";
import type { UserPrescription } from "backend/resources/userPrescription/userPrescription";
import {
  useRefillPrescription,
  useUpdatePrescriptionHrrx,
  useUserPrescriptionsQuery,
} from "backend/resources/userPrescription/userPrescription";
import BottomActionBar from "components/BottomActionBar/BottomActionBar";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { DefaultCheckbox } from "components/Checkbox";
import { LoadingSpinner } from "components/LoadingSpinner";
import { PageContainer } from "components/PageContainer";
import { useWindowSize } from "hooks/useWindowSize";
import { CarePilotRoute, useAppNavigate, useNavigateBack } from "lib/routing";
import { useState } from "react";
import { ResponsiveModal } from "shared/ui/responsive-modal";
import { useUserStore } from "state/user";

// TODO this whole thing needs to be redone... No need for a seperate page for this

export function IntakeView() {
  const navigate = useAppNavigate();
  const { isMobile } = useWindowSize();
  
  const content = (
    <div className="flex flex-col h-full gap-8">
      <PrescriptionsSection />

      {/* mobile only */}
      <div className="md:hidden">
        <BottomActionBar
          centerSlot={
            <ButtonWithIcon
              onClick={() => navigate({ path: CarePilotRoute.DAY_INTAKE_NEW })}
              icon={IconOption.PLUS}
              text=""
            />
          }
        />
      </div>
    </div>
  );

  return isMobile ? <PageContainer>{content}</PageContainer> : content;
}

// - prescriptions

function PrescriptionsSection() {
  const navigate = useAppNavigate();
  const navigateBack = useNavigateBack();
  const { isMobile } = useWindowSize();
  const { userPrescriptions } = useUserPrescriptionsQuery();
  const updateHrrx = useUpdatePrescriptionHrrx();

  const handleHrrxChange = async (prescriptionId: string, newValue: boolean) => {
    await updateHrrx.mutateAsync({
      prescriptionId,
      isHrrx: newValue
    });
  };

  return (
    <div className="flex flex-col gap-4 max-w-5xl px-4 md:px-6">
      {/* Back button - mobile only */}
      <div className="md:hidden">
        <ButtonWithIcon
          onClick={navigateBack}
          text="Back"
          icon={IconOption.BACK_ARROW}
          size="small"
        />
      </div>
      
      {/* Header section */}
      <div className="flex flex-col gap-2">
        <h2 className="text-gray-600"></h2>
        <ButtonWithIcon
          size="small"
          icon={IconOption.PLUS}
          text="Add Prescription"
          onClick={() => navigate({ path: CarePilotRoute.DAY_INTAKE_NEW })}
          className="text-blue-600 w-fit"
        />
      </div>

      {/* Table section */}
      <div className="w-full">
        {/* Table header */}
        <div className="grid grid-cols-12 gap-4 pb-2 border-b">
          <div className="col-span-5 text-sm font-medium text-gray-600">Name</div>
          <div className="col-span-4 text-sm font-medium text-gray-600">Dosage</div>
          <div className="col-span-1 text-sm font-medium text-gray-600 text-center">HRRx</div>
          <div className="col-span-2 text-sm font-medium text-gray-600 text-center">Doctor Notes</div>
        </div>
        
        {/* Table rows */}
        {userPrescriptions?.map((prescription) => (
          <div 
            key={prescription.dispensable_drug_id} 
            className="grid grid-cols-12 gap-4 py-4 border-b items-center hover:bg-gray-50"
          >
            <div className="col-span-5">{prescription.routed_dose_form_drug_desc}</div>
            <div className="col-span-4 text-gray-600">{prescription.med_strength}</div>
            <div className="col-span-1 flex justify-center">
              <DefaultCheckbox 
                checked={prescription.is_hrrx}
                onChange={() => handleHrrxChange(prescription.id, !prescription.is_hrrx)}
                label=""
              />
            </div>
            <div className="col-span-2 flex justify-center">
              <DoctorNotesPopover prescription={prescription} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function RefillButtonPopover({
  prescription,
}: {
  prescription: UserPrescription;
}) {
  const authUser = useUserStore((state) => state.user);
  const authUserId = authUser?.id;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { refill } = useRefillPrescription();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRefill = async () => {
    try {
      await refill(prescription); // Pass the prescription object
    } catch (error) {
      console.error("Error refilling prescription:", error);
    }
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "prescription-refill-popover" : undefined;

  return (
    <div>
      <button
        aria-describedby={id}
        className={`flex items-center gap-2 p-2 text-sm rounded-sm ${open && "bg-zinc-50"
          }`}
        onClick={handleClick}>
        <RefillIconSvg className="w-6 h-6" />
      </button>
      <Popover
        elevation={2}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}>
        <div className="flex flex-col items-center w-96 text-sm p-6">
          <span className="p-2 text-center">
            Would you like to refill this prescription?
          </span>
          <div className="flex gap-3">
            <ButtonWithIcon
              onClick={handleClose}
              className="p-2"
              text="Cancel"
              icon={IconOption.CANCEL}
            />
            <ButtonWithIcon
              onClick={handleRefill}
              className="p-2"
              text="Yes, refill prescription"
              icon={IconOption.CHECKMARK}
            />
          </div>
        </div>
      </Popover>
    </div>
  );
}

function DoctorNotesPopover({ prescription }: { prescription: UserPrescription }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <ButtonWithIcon
        text=""
        icon={IconOption.NOTES}
        size="small"
        onClick={() => setIsOpen(true)}
        className="hover:bg-gray-100 rounded-full"
      />
      <ResponsiveModal
        isOpen={isOpen}
        onClose={handleClose}
        title={prescription.routed_dose_form_drug_desc}
        closeText="Close"
        footerButtons={
          <ButtonWithIcon
            onClick={handleClose}
            text="Close"
            size="small"
            icon={IconOption.CHECKMARK}
          />
        }
      >
        <div className="flex flex-col gap-4">
          <ul className="list-disc pl-4 space-y-2">
            {prescription.doctor_comments?.split('\n').map((comment, index) => (
              <li key={index}>{comment}</li>
            ))}
          </ul>
        </div>
      </ResponsiveModal>
    </div>
  );
}

// - interactions

function InteractionsSection() {
  const authUser = useUserStore((state) => state.user);
  const authUserId = authUser?.id;
  const { isMobile } = useWindowSize();

  // user screen profile
  const { userPrescriptions } = useUserPrescriptionsQuery();

  const userPrescriptionDrugIds = userPrescriptions?.reduce<string[]>(
    (result, prescription) => {
      if (prescription.dispensable_drug_id !== null) {
        result.push(prescription.dispensable_drug_id);
      }
      return result;
    },
    []
  );

  const { isLoadingDrugScreen, severe_DDI, serious_DDI, moderate_DDI } =
    useDrugScreenQuery(userPrescriptionDrugIds);

  return (
    <div className={`flex flex-col gap-4 ${isMobile ? "pb-36" : ""}`}>
      {/* section header */}
      <div className="flex items-center gap-2">
        {/* icon */}
        <div className="flex items-center justify-center w-8 h-8">
          <InteractionsIconSvg />
        </div>
        {/* text */}
        <h2 className="font-light text-2xl">Drug Interactions</h2>
      </div>
      {/* section content */}
      {userPrescriptions?.length === 0 ? (
        <div className={`${isMobile ? "pl-4" : "pl-10"} text-gray-400`}>
          None
        </div>
      ) : isLoadingDrugScreen ? (
        <div className="flex w-full h-full items-center justify-center">
          <LoadingSpinner className="w-20 h-20" />
        </div>
      ) : (
        <div className={`${isMobile ? "pl-4" : "pl-10"}`}>
          {/* severe */}
          <div className="flex flex-col">
            <div className="flex items-center gap-2">
              <SevereInteractionIconSvg />
              <h3>Severe</h3>
            </div>
            <ul>
              {severe_DDI?.length > 0 ? (
                severe_DDI.map((val: any) => {
                  return (
                    <li className="list-disc list-inside pl-8">
                      {
                        userPrescriptions?.find(
                          (prescription) =>
                            prescription.dispensable_drug_id ===
                            val.screenDrug1ID
                        )?.routed_dose_form_drug_desc
                      }{" "}
                      and{" "}
                      {
                        userPrescriptions?.find(
                          (prescription) =>
                            prescription.dispensable_drug_id ===
                            val.screenDrug2ID
                        )?.routed_dose_form_drug_desc
                      }
                    </li>
                  );
                })
              ) : (
                <li className="list-disc list-inside pl-8">None</li>
              )}
            </ul>
          </div>
          {/* serious */}
          <div className="flex flex-col pt-4">
            <div className="flex items-center gap-2">
              <SeriousInteractionIconSvg />
              <h3>Serious</h3>
            </div>
            <ul>
              {serious_DDI?.length > 0 ? (
                serious_DDI.map((val: any) => {
                  return (
                    <li className="list-disc list-inside pl-8">
                      {
                        userPrescriptions?.find(
                          (prescription) =>
                            prescription.dispensable_drug_id ===
                            val.screenDrug1ID
                        )?.routed_dose_form_drug_desc
                      }{" "}
                      and{" "}
                      {
                        userPrescriptions?.find(
                          (prescription) =>
                            prescription.dispensable_drug_id ===
                            val.screenDrug2ID
                        )?.routed_dose_form_drug_desc
                      }
                    </li>
                  );
                })
              ) : (
                <li className="list-disc list-inside pl-8">None</li>
              )}
            </ul>
          </div>
          {/* moderate */}
          <div className="flex flex-col pt-4">
            <div className="flex items-center gap-2">
              <ModerateInteractionIconSvg />
              <h3>Moderate</h3>
            </div>
            <ul>
              {moderate_DDI?.length > 0 ? (
                moderate_DDI.map((val: any) => {
                  return (
                    <li className="list-disc list-inside pl-8">
                      {
                        userPrescriptions?.find(
                          (prescription) =>
                            prescription.dispensable_drug_id ===
                            val.screenDrug1ID
                        )?.routed_dose_form_drug_desc
                      }{" "}
                      and{" "}
                      {
                        userPrescriptions?.find(
                          (prescription) =>
                            prescription.dispensable_drug_id ===
                            val.screenDrug2ID
                        )?.routed_dose_form_drug_desc
                      }
                    </li>
                  );
                })
              ) : (
                <li className="list-disc list-inside pl-8">None</li>
              )}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}
