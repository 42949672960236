import { Button } from "components/Button";
import { Arrow } from "icons/Arrow";
import { FC } from 'react';

interface PAFExpandableFormButtonProps {
  rotate: boolean;
  onClick: () => void;
  label: string;
}

export const PAFExpandableFormButton: FC<PAFExpandableFormButtonProps> = ({ onClick, rotate, label }) => {
  return (
    <Button className="text-left text-black border flex items-center w-full" intent={"secondary"} type="button" onClick={onClick}>
      <span className="transform transition-transform duration-200 mr-6" style={{ transform: rotate ? 'rotate(-180deg)' : 'rotate(0deg)' }}>
        <Arrow fill={"orange"} />
      </span>
      <span>{label}</span>
    </Button>
  )
}