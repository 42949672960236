import { TaskStatus, TaskStatusColor, TaskStatusLabel, TaskWithGuideInfo } from "backend/resources/planEntry";
import { HighChartDataPoint, HighPieChart } from "components/HighChart";

interface TaskHighPieChartProps {
  data: TaskWithGuideInfo[];
  title?: string;
  isLoading?: boolean;
}
const OVERDUE = " (Overdue)";

export function TaskHighPieChart({ data, title = undefined, isLoading = false }: TaskHighPieChartProps) {
  function generateHighChartDonutChartData(tasks: TaskWithGuideInfo[]): HighChartDataPoint[] {
    const statusDict: Record<string, HighChartDataPoint> = {} as Record<string, HighChartDataPoint>;
  
    tasks.forEach((task) => {
      const status = task.status as TaskStatus;
      const isTaskOverdue = task.scheduled_date_time && new Date(task.scheduled_date_time) < new Date() && status !== TaskStatus.Done;
      const label = `${TaskStatusLabel[status]}${isTaskOverdue ? OVERDUE : ""}`;
      if (!statusDict[label]) {
        statusDict[label] = {
          name: label,
          y: 0,
          color: isTaskOverdue? TaskStatusColor.Overdue : TaskStatusColor[status],
        };
      }
      statusDict[label].y += 1;
    });

    // convert dict to array and sort by name
    return Object.values(statusDict).sort((a, b) => b.name.localeCompare(a.name));
  }

  return (
    <HighPieChart
      data={generateHighChartDonutChartData(data ?? [])}
      title={title}
      isLoading={isLoading}
      showTooltip={true}
      showDataLabels={false}
      convertToDonut={true}
      showLegend={true}
      size={"100%"}
      height={"50%"}
    />
  )
}
