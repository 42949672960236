import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import { DefaultRadioInput } from "components/RadioGroup";
import React from "react";

export interface Option {
  value: any;
  label: string;
}

interface RadioButtonsGroupProps<T> {
  id: string;
  label?: string;
  options: Option[];
  value: T;
  disabled?: boolean;
  onChange: (value: string) => void;
  row?: boolean;
}

export const RadioButtonsGroup = React.forwardRef<HTMLDivElement, RadioButtonsGroupProps<any>>((props, ref) => {
  const { id, label, options, value, disabled, onChange, row = true } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange((event.target as HTMLInputElement).value);
  };

  return (
    <FormControl>
      {label && <FormLabel id={id}>{label}</FormLabel>}
      <RadioGroup
        row={row}
        aria-labelledby={id}
        name="row-radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        <div className={`flex ${row ? 'flex-row gap-2' : 'flex-col'} `}>
          {options.map((option) => {
            return (
              <FormControlLabel
                control={<DefaultRadioInput />}
                key={option.value}
                value={option.value}
                label={option.label}
                disabled={disabled}
                sx={{
                  ".MuiFormControlLabel-label": {
                    fontFamily: "Poppins",
                    fontSize: "0.875rem",
                  },
                }}
              />
            );
          })}
        </div>
      </RadioGroup>
    </FormControl>
  );
})
