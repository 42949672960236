import { UserAdlo } from "backend/resources/userAdlo";
import { PAFStatus } from "components/CarespacePage/PAFSubmission/pafEnums";
import { GuideAlignmentStatus, PAFButtonLabel } from "components/CarespacePage/Tabs/CarespaceMainTab/constants";


export const getGuideAlignmentPillLabel = (pafSubmissionStatus: PAFStatus, guideAlignmentStatus: GuideAlignmentStatus) => {
  if (pafSubmissionStatus == PAFStatus.READY) {
    return !guideAlignmentStatus ? pafSubmissionStatus : guideAlignmentStatus
  } else if (pafSubmissionStatus == PAFStatus.DRAFT){
    return pafSubmissionStatus
  } 
  return GuideAlignmentStatus.NA
}

export const getPAFButtonLabel = (guideAlignmentLabel: PAFStatus | GuideAlignmentStatus) => {
  if (guideAlignmentLabel == PAFStatus.READY || guideAlignmentLabel == PAFStatus.DRAFT) {
    return PAFButtonLabel.EDIT
  } else if (guideAlignmentLabel == GuideAlignmentStatus.SUBMITTED || guideAlignmentLabel == GuideAlignmentStatus.ALIGNED) {
    return PAFButtonLabel.VIEW
  }
  return PAFButtonLabel.NEW
}

export const buildPAFFileName = (userAdlo: UserAdlo | null) => {
  if (!userAdlo) {
    return `paf_submission_${new Date().toLocaleString()}`
  }
  return `${userAdlo?.first_name}_${userAdlo?.last_name}_paf_submission_${new Date().toLocaleString()}`
}