import { PAFStatus } from "components/CarespacePage/PAFSubmission/pafEnums";

export enum GuideAlignmentStatus {
  ALIGNED = "ALIGNED",
  SUBMITTED = "SUBMITTED",
  NA = "N/A"
}

export enum PAFButtonLabel {
  NEW = "New",
  EDIT = "Edit", 
  VIEW = "View"
}

export const GuideAlignmentPillColorMap = {
  [GuideAlignmentStatus.NA]: "#CFCFCF",
  [PAFStatus.DRAFT]: "#F6B26B",
  [PAFStatus.READY]: "#7DC3F6",
  [GuideAlignmentStatus.SUBMITTED]: "#8690EE",
  [GuideAlignmentStatus.ALIGNED]: "#25C322",
}
